import React, { useState, useEffect } from "react";
import Webcam from "react-webcam";
import { toast } from "react-toastify";
import {  useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { handleChange } from "../redux/userSlice";
// import { useDispatch } from "react-redux";
// import axios from "axios";
// import { Spinner } from "react-bootstrap";
// import { saveNin } from "../redux/apiCalls";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import authFetch from "../axios";
import { STEP, SUCCESS_STATUS_DIGIT } from "../utils";
// import { Navigate } from "react-router-dom";
// import verxidPopupSetup from "../scripts/verxid";



  

export const WebcamCapture = () => {
  // const { usertype } = useSelector((state) => state.user);
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [image, setImage] = useState("");
  // eslint-disable-next-line 
  const [image2, setImage2] = useState(
    sessionStorage.getItem("photo")
  );
  // const bearerToken = sessionStorage.getItem("userToken");
  // const accessCode=   sessionStorage.getItem("access_code")
  const videoConstraints = {
    width: 220,
    height: 200,
    facingMode: "user",
  };

  // const videoConstraints = {
  //   width: 220,
  //   height: 200,
  //   facingMode: "user",
  // };
  // const [popup, setPopup] = useState(new verxidPopupSetup({
  //   auth_key: "c96cb80421669ck38ca8c2e53d2d2",
  //   webhook:
  //     "aHR0cHM6Ly9hcGkudmVyeGlkLnNpdGUvbXRuL210bi1zZXJ2aWNlcy93ZWJob29r",
  //   identifier: "2345224",
  //   widgetType: "LIVENESS",
  //   callback: (data)=>{ handlePopupCallback(data) },
  // }));
  // const captureFinger = () => {
  //   popup.init()
  // };
  // const handlePopupCallback = (data) => {
  //   console.log("Popup data:", data);
  //   setImage(data.image)
  //   toast.success("image captured!proceed to match face.", {
  //     position: "top-center",
  //   });
  //   popup.closePopup();
  // };              
  // const num =   sessionStorage.getItem("nin");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  // eslint-disable-next-line
  const [loadingSave, setLoadingSave] = useState(false);
  const webcamRef = React.useRef(null);

// eslint-disable-next-line
//   const handleSave= async ()=>{
//     if (!image) {
//       toast.error("please capture image", {
//         position: "top-center",
//       });
//       return;
//     } 
//     setLoadingSave(true);
//     try {
// var formdata = new FormData();
// formdata.append("access-code", num);
// formdata.append("face", image);

// var config = {
//   method: "post",
//   url: "https://api.verxid.site/npc/unicef/update-face",
//   headers: {
//     Authorization: "Basic YmFybmtzZm9ydGUtbmltYzowbmx5YmFybmtzMTIz",
//   },
//   data: formdata,
// };    

             
// const { data } = await axios(config);
// console.log(data.nimc);
// if (data.nimc.status === 0) {
//   toast.error(data.nimc.message, {
//     position: "top-center",
//   });
// } else if (data.nimc.status === 1) {
//   toast.success(data.nimc.message, {
//     position: "top-center",
//   });
//   setLoadingSave(false);
// //   sessionStorage.removeItem("dataMine");
// // sessionStorage.clear();
// navigate("/test");
  
// }
// } catch (error) {
// setLoadingSave(false);
// console.log(error);
// toast.error("please retry", {
//   position: "top-center",
// });
// }
    

//   }

  const handleSubmit = async () => {
    // console.log(image)
    if (!image) {
      toast.error("please capture image", {
        position: "top-center",
      });
    } else {
        setLoadingSubmit(true);
          try {
      var ninImage = {
        template:image2,
        format:'JPEG'
      }  
      

      var capturedImage={
        template:image,
        format:'JPEG'
      }
      const sends = {
        ninImage,
        capturedImage,
        level:'HIGH'
    };

     
      var config = {
        method: "post",
        url: `/face/verify`,
        data: sends,
      };
                  
                    
    
      const { data } = await authFetch(config);
      console.log(data);
      let score = data.palliative.score;
      if (data.palliative.status ===SUCCESS_STATUS_DIGIT.SUCCESS ) {
        setLoadingSubmit(false);
        Swal.fire({
          title: `Face Successfully Matched with score ${score}`,
          text: ``,
          icon: 'success',
          confirmButtonColor: '#0b6916',
   confirmButtonText: `Continue`,
   allowOutsideClick:false
        }).then(async (result) => {
              if(result.value){  
                sessionStorage.setItem('faceMatched',true)
                navigate('/contact');
              }  
           })
      }
       else { 
        toast.error(`Face verification unsuccessful ${score?`with score of ${score}`:''}`, {
          position: "top-center",
        });
        setLoadingSubmit(false);
      }
    
    } catch (error) {
      setLoadingSubmit(false);
      console.log(error);
      toast.error("please retry", {
        position: "top-center",
      });
    }
        
    }
  };
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    console.log('FileSize: ' + imageSrc.length);
    console.log(imageSrc);
if(imageSrc.length > 5044562){
  toast.error("image should be less than 5mb.please retake image.", {
    position: "top-center",
  });
return;
}const realImg = imageSrc.split(',')[1]
// console.log(imageSrc.split(','))
    setImage(realImg);
}, [webcamRef]);

    
  useEffect(() => {
    const getDetails=async ()=>{
      // setPreLoading(true);
      try {
        var config = {
          method: "get",
          url: "/auth/retrive-data",
        };
        const { data } = await authFetch(config);
        const usePhoto = sessionStorage.getItem("photo");
        if(data.palliative.step >= STEP.FACE){
          sessionStorage.clear();
          // navigate('/')
        }
        else {
        if(!usePhoto){
          try {
            var jsonData ={
              nin:data.palliative.nin
             }
            var config1 = {
              method: "post",
              // https://api.verxid.site/npc/unicef/confirmNin?nin=39294631875
              url: `/nin/verify`,
              // url:`http://3.64.182.48/npc/verifyNin?nin=${ninNum}`,
             data:jsonData
                  
            };
            const { data:data1} = await authFetch(config1);
            // console.log(data);
            if(data1.palliative.photo !=='' || null){
              setImage2(data1.palliative.photo)
            }
          }
         catch(error) {
          console.log(error);
          toast.error("Unable to verify NIN image", {
            position: "top-center",
          });
          }
        }
      }
        // setPreLoading(false);
      } catch (error) {
        console.log(error);
        // setPreLoading(false);
      }
    }
    getDetails();
  }, [])


  return (
    <div className="pars">
      <h4>Facial Capture</h4>
      <p className="text-center">
        Please ensure to be in a good position and under a good light source
        before capturing.
      </p>
     <div className="webcam-container">
        <div className="webcam-img">
          {image === "" ? (
            <Webcam
              audio={false}
              height={200}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={220}
              videoConstraints={videoConstraints}
            />
          ) : (
            <img src={image} alt="" />
          )}
        </div> 
         <div>
          {image !== "" ? (
            <button
              onClick={(e) => {
                e.preventDefault();
                setImage("");
              }}
              className="webcam-btn my-btn btn-block my-1"
            >
              Retake Image
            </button>
          ) : (
            <button
              onClick={(e) => {
                e.preventDefault();
                capture();
              }}
              className="webcam-btn my-2 my-btn btn-block my-1"
            >
              Capture
            </button>
          )}
        </div> 
      </div>

      <div className="btn-row">
      {/* <button className="redz my-btn btn-block mt-4" disabled={loadingSave} onClick={handleSave}>
              {loadingSave && <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
          className='mx-2'
        />  
              } 
         SAVE AND CLOSE APPLICATION
               
              </button> */}
             
           
        {/* <button
          className="my-btn  btn-clear px-5 mt-4 d-block btn-block"
          onClick={() => captureFinger()}
        >
      {image?'Recapture':'Capture'}
        </button> */}

 <button
          className="my-btn mt-4  px-5 d-block btn-block"
          // disabled={isLoading}
          onClick={handleSubmit}
          disabled={loadingSubmit}
        >
         Match face
        </button> 
      </div>
    </div>
  );
};
