import React, { useState,useEffect } from "react";
import styled from "styled-components";
import { Form,Spinner } from "react-bootstrap";
// import axios from "axios";
import { toast } from "react-toastify";
import pic from "../assets/npc/water_5.png";
import Swal from 'sweetalert2/dist/sweetalert2.js';
// import { saveNin } from "../redux/apiCalls";
// import { useSelector } from "react-redux";
import {  useNavigate } from "react-router-dom";
import { saveNin } from "../redux/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import authFetch from "../axios";
// import { handleChange } from "../redux/userSlice";

const Nin = () => {
    // const { usertype,dqmCode } = useSelector((state) => state.user);
    const { usertype } = useSelector((state) => state.user);
    const [ninNum, setNinNum] = useState("");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
 
    const handleAppStart = async (e) => {
      e.preventDefault();
      setLoading(true);
      if(ninNum.length> 11 || ninNum.length <11){
        Swal.fire({
                  title: 'NIN number must be 11 digits',
                  text: 'retry later',
                  icon: 'error',
                  confirmButtonColor: '#0b6916',
           confirmButtonText: 'OKAY'
                })
                setLoading(false);
return;
      }
      try {
        var jsonData ={
          nin:ninNum 
         }
        var config = {
          method: "post",
          // https://api.verxid.site/npc/unicef/confirmNin?nin=39294631875
          url: `/nin/verify`,
          // url:`http://3.64.182.48/npc/verifyNin?nin=${ninNum}`,
         data:jsonData
              
        };
        const { data } = await authFetch(config);
        console.log(data);
        if (data.palliative.statusCode !== 200 ) {
          setLoading(false);
          Swal.fire({
            title: 'NIN verification failed',
            text: 'retry again',
            icon: 'error',
            confirmButtonColor: '#0b6916',
     confirmButtonText: 'OKAY'
          })
      return;
        } 

        else if (data.palliative.statusCode === 200 && data.palliative.firstname===null) {
          setLoading(false);
          Swal.fire({
            title: `${data.palliative.message ||'please retry'}`,
            text: 'retry later',
            icon: 'error',    
            confirmButtonColor: '#0b6916',
     confirmButtonText: 'OKAY'
          })
          return;
      }
                          
     

      else if (data.palliative.statusCode === 200 && data.palliative.firstname==="") {
        setLoading(false);
        Swal.fire({
          title: `${data.palliative.message ||'please retry'}`,
          text: 'retry',
          icon: 'error',

          confirmButtonColor: '#0b6916',
   confirmButtonText: 'Okay'
        })
       return;
      }
      else if (data.palliative.is_exist === true) {
        setLoading(false);
        Swal.fire({
          title: 'NIN already used',
          text: 'used NIN.',
          icon: 'error',


          confirmButtonColor: '#0b6916',
   confirmButtonText: 'Okay'
        }) 
        return;
      }
  //     else if (data.palliative.statusCode === 400) {
  //       setLoading(false);
  //       Swal.fire({
  //         title: 'please use correct NIN',
  //         text: 'wrong used NIN.',
  //         icon: 'error',

  //         confirmButtonColor: '#0b6916',
  //  confirmButtonText: 'Okay'
  //       }) 
  //     }
  
      else {
        setLoading(false);
        Swal.fire({
          title: 'NIN verification done',
          text: `${data.palliative.firstname} ${data.palliative.lastname}`,
          // text: `proceed`,
          icon: 'success',
// imageUrl:`data:image/jpeg;base64,${data.palliative.photo}`,
imageWidth:'100px',
imageHeight:'100px',
          confirmButtonColor: '#0b6916',
   confirmButtonText: 'Liveness match'
        }).then((result) => {
              if(result.value){
                sessionStorage.setItem("fname", data.palliative.firstname);
                sessionStorage.setItem("lname", data.palliative.lastname);
                sessionStorage.setItem("dob", data.palliative.dob);
                sessionStorage.setItem("gender", data.palliative.gender);
                sessionStorage.setItem("phone", data.palliative.phone);
                sessionStorage.setItem("photo", data.palliative.photo);
                sessionStorage.setItem("nin", ninNum);
                // saveNin(ninNum,usertype,dqmCode);
                // proceeed too test page....
                setLoading(false);
                const dqm =  sessionStorage.getItem("access_code") || "";
                // navigate('/face-capture');
                // return if said otherwise...
                // navigate('/face-capture')
                // window.location.href='/bio-data';
                // sessionStorage.setItem('testPassed',true);

                 saveNin(ninNum,usertype,dqm,
                 data.palliative.firstname,
                 data.palliative.lastname,
                 '',
                 data.palliative.dob,
                 data.palliative.gender,
                 dispatch).then((res)=>{
console.log(res);
// return;
if(res=== true ){         
  window.location.href='/face-capture';
}  
                })

// sessionStorage.setItem("access_code", data.npc.access_code);
// dispatch(handleChange({name:'accessCode',value:data.npc.access_code}))
// sessionStorage.setItem('testPassed',true);
// dispatch(handleChange({name:'usertype',value:data.npc.userType}))
// sessionStorage.setItem("user-type",data.npc.userType)
// window.location.href='/bio-data';
            }
           })  
      } 
    }
  catch (error) {
        setLoading(false);
        console.log(error);
        toast.error(`${error.response.data.palliative.message ||'please retry'}`, {
          position: "top-center",
        });
      }
    };

useEffect(() => { 
  const access = sessionStorage.getItem("checkFirst");
  // const nin = sessionStorage.getItem("nin");
  if(!access){
    navigate("/");
  }
}, [navigate])

       
               
    return (
      <Wrapper className="nin-container">
        <div className="container">
          <div className="row">
            <div className="col-10 mx-auto">
              <Form className="" onSubmit={handleAppStart}>
                <h4>verify NIN</h4>
                <p>Registration agents  National Identification Number (NIN) can only be used once to access the assessment test.</p>
                <Form.Group className="mb-3" controlId="formBasicEText">
                  <Form.Label className="text-left">National Identification Number(NIN)</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    placeholder="Enter Your NIN"
                    className="form_input shadow-none"
                    name="nin_code"
                    onChange={(e) => setNinNum(e.target.value)}
                    maxLength={11}
                    minLength={11}
                    onInput={(e) => e.target.value = e.target.value.slice(0, 11)}
                  />
                </Form.Group>
                <button type="submit" className="my-btn">
                Validate NIN
                </button>
              </Form>
            </div>
          </div>
          {loading &&
                <div className="row py-3">
  <div className="col-6  col-md-6 mx-auto">
  <Spinner animation="border" variant="success" />
  </div>
  </div>}
        </div>

      </Wrapper>
    );
  };

  const Wrapper = styled.section`
    min-height:100vh ;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-image: url(${pic});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  `;
export default Nin;
