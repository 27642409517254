import React,{useEffect,useState} from "react";
import styled from "styled-components";
// import { Spinner,Alert} from "react-bootstrap";
import pic2 from "../assets/npc/water_5.png";
import { useDispatch } from "react-redux";
import { handleChange } from "../redux/userSlice";
// import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import Title from "../components/Title";
import { Form, Spinner } from "react-bootstrap";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import authFetch from "../axios";
import { STEP } from "../utils";
const Prev = () => { 

const navigate = useNavigate();
const dispatch=useDispatch();
 // eslint-disable-next-line
const [rez,setRez] = useState({})
 // eslint-disable-next-line
const [loading,setLoading] = useState(false)
const [disabled,setDisabled] = useState(true)
const handleEdit=(name)=>{
    const nm = name;
   
   dispatch(handleChange({ name:nm, value:true }));
   sessionStorage.setItem(nm,true)
   if(nm==='editBio'){
    console.log(nm);
    window.location.href='/bio-data';
   }
   else if(nm==='editRes'){
    console.log(nm);
    window.location.href='/residence';
   }

   else if(nm==='editCon'){
    console.log(nm);
    window.location.href='/contact';
   }

   else if(nm==='editEdu'){
    console.log(nm);
    navigate('/education-data');
   }
   
}


    
  // const bearerToken = sessionStorage.getItem("userToken");

      const handleFormSubmit = async (e) => {
        e.preventDefault();
        Swal.fire({
          title: 'Application Successfully Submitted',
          text: 'Confirmation message is sent to the registered email address.',
          icon: 'success',
          confirmButtonColor: '#0b6916',
   confirmButtonText: 'OKAY',
   allowOutsideClick:false
        }).then((result) => {
          if(result.value){
            sessionStorage.removeItem("dataMine");
            sessionStorage.clear();
            navigate('/')
          }
        })
      };


      
useEffect(() => {
  // const access = sessionStorage.getItem("access_code");   
  const getDetails=async ()=>{
    setLoading(true);
    try {
      var config = {
        method: "get",
        url: "/auth/retrive-data", 
      };
      const { data } = await authFetch(config);
      // console.log(data.palliative[0])
      if(data.palliative.step < STEP.BANKING){
navigate(-1);
      }
      sessionStorage.setItem('dataMine',JSON.stringify(data.palliative))
      setRez(data.palliative)
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }


  getDetails()
  
}, [navigate])

  return (
    <Wrapper>
    <div className="container">
    <Title title="form preview"/>
    {loading ? <Spinner  
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
          className='mx-auto'/>

          : <div>
 <div className="cont-1">

<div className="row">
   <h5 style={{color:"green",fontWeight:"bold"}}>Bio Data</h5> 
</div>
<div className="row py-3">
<div className="col-6 col-md-3 col-lg-3">
<h5>surname</h5>
<p>{rez?.bio_data?.lastname??''}</p>
</div>

<div className="col-6 col-md-3 col-lg-3">
<h5>middle name</h5>
<p>{rez?.bio_data?.middlename??''}</p>
</div>

<div className="col-6 col-md-3 col-lg-3">
<h5>first name</h5>
<p>{rez?.bio_data?.firstname??''}</p>
</div>

<div className="col-6 col-md-3 col-lg-3">
<h5>date of birth</h5>
<p>{rez?.bio_data?.dob??''}</p>
</div>
</div>
<hr></hr>


<div className="row py-3">
<div className="col-6 col-md-3 col-lg-3">
<h5>sex</h5>
<p>{rez?.bio_data?.gender??''}</p>
</div>


<div className="col-6 col-md-3 col-lg-3">
<h5>state of origin</h5>
<p>{rez?.bio_data?.state??''}</p>
</div>

<div className="col-6 col-md-3 col-lg-3">
<h5>LGA of Origin</h5>
<p>{rez?.bio_data?.lga??''}</p>
</div>

<div className="col-6 col-md-3 col-lg-3">
<h5>Physical Challenge</h5>
<p>{rez?.bio_data?.physical_challenge??''}</p>
</div>
</div>
<hr></hr>

<div className="row py-3">
<div className="col-6 col-md-3 col-lg-3">
<h5>Computer Proficiency</h5>
<p>{rez?.bio_data?.computer_skill??''}</p>
</div>

<div className="col-6 col-md-3 col-lg-3">
<h5>Language Proficiency</h5>
<p className="text-wrap">{rez?.bio_data?.language??''}</p>
</div>


<div className="col-6 col-md-3 col-lg-3">
<h5>Next Of kin</h5>
<p>{rez?.bio_data?.next_kin_name??''}</p>
</div>
<div className="col-6 col-md-3 col-lg-3">
<h5>Criminal Case Conviction</h5>
<p>{rez?.bio_data?.criminal_record??''}</p>
</div>
</div>
<hr></hr>
                       

<div className="row py-3">


<div className="col-6 col-md-3 col-lg-3">
<h5>Next Of kin Phone Number</h5>
<p>{rez?.bio_data?.next_kin_phone??''}</p>
</div>

<div className="col-6 col-md-3 col-lg-3">
<h5>Relationship with next Of kin</h5>
<p>{rez?.bio_data?.next_kin_relationship??''}</p>
</div>

<div className="col-6 col-md-3 col-lg-3">
<h5>Other language</h5>
<p>{rez?.bio_data?.other_language??''}</p>
</div>


<div className="col-6 col-md-3 col-lg-3 ">

</div>
 <div className="col-6 col-md-3 col-lg-3 ml-auto">
<button className="my-btn" onClick={()=>handleEdit('editBio')}>EDIT</button>
</div> 


</div>
</div>

<div className="cont-2">
<div className="row">
   <h5 style={{color:"green",fontWeight:"bold"}}>Place Of Permanent Residence</h5> 
</div>
<div className="row py-3">

<div className="col-6 col-md-3 col-lg-3">
<h5>state of primary assignment</h5>
<p>{rez?.residence?.state_resid??''}</p>

</div>

<div className="col-6 col-md-3 col-lg-3">
<h5>LGA of primary assignment</h5>
<p>{
rez?.residence?.lga_resid??''}</p>
</div>
<div className="col-12 col-md-3 col-lg-3">
<h5>Ward of primary assignment</h5>
<p>{rez?.residence?.ward??''}</p>
</div>

<div className="col-6 col-md-3 col-lg-3">
<h5>Usual Residential Address</h5>
<p>{rez?.residence?.address??''}</p>
</div>


</div>
<div className="row">
=<div className="col-6 col-md-3 col-lg-3 ms-auto">
<button className="my-btn" onClick={()=>handleEdit('editRes')}>EDIT</button>
</div> 
</div>
</div>

<div className="cont-3">
<div className="row">
   <h5 style={{color:"green",fontWeight:"bold"}}>Contact Information</h5> 
</div>
<div className="row py-3">
<div className="col-12 col-md-8 col-lg-8">
<h5>Email Address</h5>
<p>{rez?.contact?.email??''}</p>
</div>

<div className="col-12 col-md-4 col-lg-4">
<h5>Mobile Number</h5>
<p>{rez?.contact?.phone??''}</p>
</div>



</div>

{/* <div className="row">
<div className="col-6 col-md-3 col-lg-3 ms-auto">
<button className="my-btn" onClick={()=>handleEdit('editCon')}>EDIT</button>
</div>
</div> */}
</div>







{/* row2 */}
<div className="cont-6">
<div className="row">
   <h5 style={{color:"green",fontWeight:"bold"}}>Other Details</h5> 
</div>
<div className="row py-3">
<div className="col-6 col-md-6 col-lg-4">
<img src={`data:image/jpeg;base64,${rez?.face??''}`} className='img-fluid' alt=''/>
</div>

<div className="col-6 col-md-6 col-lg-4">
<h5>Account name</h5>
<p>{rez?.bank?.account_name??''}</p>
</div>

<div className="col-6 col-md-6 col-lg-4">
<h5>Account number</h5>
<p>{rez?.bank?.account_number??''}</p>
</div>


</div>

</div>


<div className="row py-3">
<div className="col-md-10">
<Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
              
                type="checkbox"
                label="I hereby confirm that the Information I have given is complete and true to the best of my knowledge. I understand that the provision of fake or untrue information will lead to automatic disqualification from the Palliative Agent E - Recruitment exercise."
                className="shadow_none form_check"
                onChange={
                  (e)=>{
if(e.target.checked){
setDisabled(false)
}
else{
  setDisabled(true)
}
                  }
                }
              />
            </Form.Group>
</div>
</div>

<div className="row">
<div className="col-md-6 mx-auto">

<button className="my-btn btn-block mt-4" onClick={handleFormSubmit} disabled={disabled}>PROCEED TO SUBMIT</button>
</div>
</div>
        </div>
}
</div>
        </Wrapper>
  )
}

const Wrapper = styled.section`
  
  padding: 1.6rem 0;
 min-height: calc(100vh - 107px);
  background-image:
    linear-gradient(to right,  rgba(245, 246, 252, 0.92), 
    rgba(245, 246, 252, 0.82)),
    url(${pic2});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
text-transform: uppercase;
  h5{
      margin-bottom:0;
      font-weight:300;
      font-size:1.3rem
  }

  p{
    
      font-size:1rem;
      font-weight:lighter;
      word-wrap: break-word;
  }

  .cont-1,.cont-2,.cont-3,.cont-4,.cont-5,.cont-6{
      box-shadow: var(--shadow-4);
      padding: 0.5rem  0.5rem ;
      margin-top:1.3rem
  }
  
`;
export default Prev