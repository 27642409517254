import React,{useState,useEffect,useRef} from 'react'
import Title from '../components/Title';
import { Form,Row,Col } from 'react-bootstrap';
import pic2 from "../assets/npc/water_5.png";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { handleChange,handleStChange} from "../redux/userSlice";
// import axios from "axios";
import { useNavigate } from "react-router-dom";
import Map from "./Map";
import Select from "react-select";
import { components } from "react-select";
import { toast } from "react-toastify";
import {Spinner} from "react-bootstrap";
import Progress from '../components/Progress';
import authFetch from '../axios';
import { EDIT_TYPE, USER_TYPE,SUCCESS_STATUS_DIGIT,STEP } from '../utils';
// import { StyleSheetConsumer } from 'styled-components';
const Residence = () => {
  const [lg, setLg] = useState([]);
  const [lgas, setLga] = useState([]);
  const [lgas2, setLga2] = useState([]);
  const [wards, setWards] = useState([]);
  const [wards2, setWards2] = useState([]);
  // eslint-disable-next-line
  const [count,setCount] =useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
                  
  const [preloading, setPreLoading] = useState(false);
  // eslint-disable-next-line
  const [loadingSave, setLoadingSave] = useState(false);
  const selectInputRef = useRef();
  // const selectInputRef2 = useRef();
    const navigate = useNavigate();
    const isValidNewOption = (inputValue, selectValue) =>
    inputValue.length > 0 && selectValue.length < 3;
    const Menu = props => {
      const optionSelectedLength = props.getValue().length || 0;
      return (
        <components.Menu {...props}>
          {optionSelectedLength < 1  ? (
            props.children
          ) : (
            <div style={{ margin: 15 }}>Max limit achieved</div>
          )}
        
        </components.Menu>
      );
    };       
                
    const bearerToken = sessionStorage.getItem("userToken");
    const {
        userResidenceState,
        lgaResidence,
        ward,
        address,
        mapCenter,
        editRes,
        usertype ,
        preState ,
        preLga,
        preName,
        preNameLga
      } = useSelector((state) => state.user);
      console.log(ward)
      const dispatch = useDispatch();
      // const user = sessionStorage.getItem('user-type');
// eslint-disable-next-line
      const handleSave=async (e)=>{
        // if(!ward){
        //   toast.error('please select ward', {
        //     position: "top-center",
        //   });
        //   return;
        // }
          
        // if(count.length < 1){
        //   toast.error('please select 1 ward', {
        //     position: "top-center",
        //   });
        //   return;
        // }
        if(!ward ){
          toast.error('please select ward', {
            position: "top-center",
          });
          return
        }
          
        
        if(!address ){
          toast.error('please fill all forms', {
            position: "top-center",
          });
          return;
        }

        setLoadingSave(true);
        try {
 const dat={
address:address,
state:usertype ===USER_TYPE.AGENT?preState:userResidenceState,
lga:usertype ===USER_TYPE.AGENT?preLga:lgaResidence,
ward,
lat:mapCenter?.lat.toString(),
lng:mapCenter?.lng.toString(),
edit:EDIT_TYPE.NEW
}      


 var config = {
   method: "post",
   url: "/applicant/update-residence-data",
   data: dat,
 };         
 const { data } = await authFetch(config);
 console.log(data.palliative);
 if (data.palliative.status === SUCCESS_STATUS_DIGIT.FAILURE) {
   toast.error(data.palliative.message, {
     position: "top-left",
   });   
   setLoadingSave(false);
 } else if (data.palliative.status === SUCCESS_STATUS_DIGIT.SUCCESS) {
   toast.success(data.palliative.message, {
     position: "top-left",
   });

   setLoadingSave(false);
   sessionStorage.removeItem("dataMine");
 sessionStorage.clear();
 navigate("/");
 }
 }
  catch (error) {
 console.log(error);
 setLoadingSave(false);
 toast.error("please retry", {
   position: "top-left",
 });
}
      }
      const handleSubmit = async (e) => {
// if(!ward){   
//   toast.error('please select center', {
//     position: "top-center",
//   });
//   return;
// }
  
// if(count.length < 1){
//   toast.error('please select 1 center', {
//     position: "top-center",
//   });
//   return;
// }
if(!ward ){
  toast.error('please select ward', {
    position: "top-center",
  });
  return
}
if(!address ){
  toast.error('please fill all forms', {
    position: "top-center",
  });
  return;
}
        if(editRes){
          setLoadingSubmit(true);
          try {
            const dat1={
              address:address,
              state:usertype ===USER_TYPE.AGENT?preState:userResidenceState,
              lga:usertype ===USER_TYPE.AGENT?preLga:lgaResidence,
              ward,
              lat:mapCenter?.lat.toString(),
              lng:mapCenter?.lng.toString(),
              edit:EDIT_TYPE.EDIT
              } 
   var config1 = {
     method: "post",
     url: "/applicant/update-residence-data",
    
     data: dat1,
   };
   const { data } = await authFetch(config1);
   
   if (data.palliative.status === SUCCESS_STATUS_DIGIT.FAILURE) {
     toast.error(data.palliative.message, {
       position: "top-left",
     }); 
     setLoadingSubmit(false);
   } else if (data.palliative.status === SUCCESS_STATUS_DIGIT.SUCCESS) {
     toast.success(data.palliative.message, {
       position: "top-left",
     });

     setLoadingSubmit(false)
     dispatch(handleChange({ name:'editRes', value:false }));
   sessionStorage.setItem('editRes',false)
     navigate("/prev");
   }
   }
    catch (error) {
   console.log(error);
   setLoadingSubmit(false)
   toast.error("please retry", {
     position: "top-left",
   });
 }
                 
              } 
              else{     
                setLoadingSubmit(true);
                 try {
                  const dat3={
                    address:address,
                    state:usertype ===USER_TYPE.AGENT?preState:userResidenceState,
                    lga:usertype ===USER_TYPE.AGENT?preLga:lgaResidence,
                    ward,
                    lat:mapCenter?.lat.toString(),
                    lng:mapCenter?.lng.toString(),
                    edit:EDIT_TYPE.NEW
                    } 
        
          var config = {
            method: "post",
            url: "/applicant/update-residence-data",
            data:dat3,
          };
          const { data } = await authFetch(config);
          console.log(data.palliative);
          if (data.palliative.status === SUCCESS_STATUS_DIGIT.FAILURE) {
            toast.error(data.palliative.message, {
              position: "top-left",
            }); 
            setLoadingSubmit(false);
          } else if (data.palliative.status === SUCCESS_STATUS_DIGIT.SUCCESS) {
            toast.success(data.palliative.message, {
              position: "top-left",
            });

            setLoadingSubmit(false)
            navigate("/banking-info");
          }
          }                                   
                                         
                                    
                                     
                                  
           catch (error) {
          console.log(error);
          setLoadingSubmit(false)
          toast.error("please retry", {
            position: "top-left",
          });
        }
               
        }
         
      };
      
      const handleStateChange = async (e) => {
        const name = e.target.name;
        const value = e.target.value;
        // const tx= e.target.options[e.target.selectedIndex].text;
        dispatch(handleChange({ name, value }));
        // dispatch(handleStChange({name:'userResStateDet',value:tx}))
      
    
        var config = {
          method: "get",
          url: `/resources/lga?stateId=${value}`,
        };
        const { data } = await authFetch(config);
        console.log(data.palliative);
     selectInputRef?.current?.clearValue(); 
        setLga(data.palliative);

        // setWards([]);
        // setUserState(e.target.value);
        // console.log(userState);
      };
    
                                      
                          
                                



      const handleMultiSelect = (selectedOption) => {
        const news = selectedOption.map((it, i) => {
          return it.id;
        });
        const nms = selectedOption.map((it, i) => {
          return it.name;
        });
        const lName='wardDet';
        const name = "ward";
        setCount(news);
        const newz=news.join(',');
        const nmz = nms.join(',');
        dispatch(handleChange({ name, value:newz }));
        dispatch(handleStChange({ name:lName, value:nmz }));
        // console.log(news,newz);
      };
      // eslint-disable-next-line
      const handleMultiSelect2 = (selectedOption) => {
        const news = selectedOption.map((it, i) => {
          return it.id;
        });
        const nms = selectedOption.map((it, i) => {
          return it.name;
        });
        const lName='wardDet';
        const name = "ward2";
        // setCount(news);
        const newz=news.join(',');
        const nmz = nms.join(',');
        dispatch(handleChange({ name, value:newz }));
        dispatch(handleStChange({ name:lName, value:nmz }));
       
      };
      const handleLgaChange = async (e) => {
        const name = e.target.name;
        const value = e.target.value;
        // const lgtx= e.target.options[e.target.selectedIndex].text;
        dispatch(handleChange({ name, value }));
        // dispatch(handleStChange({name:'lgaResDet',value:lgtx}))
        // console.log(userState);
                           

                           
        var config = {
          method: "get",
          url: `/resources/ward?lgaId=${value}`,

       
        };
        const { data } = await authFetch(config);
        console.log(data.palliative);
        setWards(data.palliative);
      };


      useEffect(() => {
        const getDetails=async ()=>{
          setPreLoading(true);
          try {
          
            var config = {
              method: "get",
              url: "/auth/retrive-data",

           
            };
            const { data } = await authFetch(config);
            console.log(data.palliative[0])
            if(data.palliative.step < STEP.BIODATA){
      navigate(-1);
            }
            // sessionStorage.setItem('dataMine',JSON.stringify(data.palliative[0]))
            // setRez(data.palliative[0])
            setPreLoading(false);
          } catch (error) {
            console.log(error);
            setPreLoading(false);
          }
        }
        getDetails();
      }, [navigate])
      
      useEffect(() => {

        const fetchState = async () => {
          try {
            const res = await authFetch.get(
              "/resources/states",
            );
            setLg(res.data.palliative);
          } catch (error) {
            console.log(error);
          }
        };

        const handleStateChangePre = async (e) => {
          // dispatch(handleChange({ name, value }));
          // dispatch(handleStChange({name:'userResStateDet',value:tx}))
       
      
          var config = {
            method: "get",
            url: `/resources/lga?stateId=${preState}`,
          };
          const { data } = await authFetch(config);
          console.log(data.palliative);
      //  selectInputRef?.current?.clearValue(); 
          setLga2(data.palliative);
             
          // setWards([]);
          // setUserState(e.target.value);
          // console.log(userState);
        };
        const handleLgaChangePre = async (e) => {
                         
          var config = {
            method: "get",
            url: `resources/ward?lgaId=${preLga}`,
         
          };
          const { data } = await authFetch(config);
          // console.log(data.palliative);
          setWards2(data.palliative);
        };
        handleStateChangePre();
        handleLgaChangePre();
        fetchState();
      }, [navigate,bearerToken,preState,preLga]);
  return ( 
    <Wrapper>
 {preloading ? <Spinner  
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
          className='mx-auto'/>
          :
<div className="container">
        <Title title="personal information"/>
        <div className="" >
          <h5 className="text-center pt-2"> Residence</h5>
          <Progress active={2}/>

          <Row>
            <Col md={12} lg={12} className="p-2" style={{
              background:'#f5f5f5',margin:'9px 0px'
            }}>   
             <div> <span className='' style={{
              color:'red',width:'100%',margin:'opx 8px'
             }}>NOTE:</span>User who selects wards outside their residential address will be fully responsible for their transportation when carrying out their duties and when found out, will result to the termination of employment. Applicants must select wards within their residential address</div>
            </Col>
          </Row>

          {/* <Row>
          <Col md={4} lg={4}>
              <Form.Label className="text-left">
                Select State Of Residence
              </Form.Label>
              <Form.Select
                required
                aria-label="Default select example"
                name="userResidenceState2"
              
                onChange={handleStateChange2}
                className="shadow-none form-select"
              >
                <option value="">Select State of Residence</option>
                {lg.map((it, i) => {
                  return (
                    <option key={it.id} value={it.id}>
                      {it.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>

            <Col md={4} lg={4}>
              <Form.Label className="text-left">
                Select LGA of Residence
              </Form.Label>
              <Form.Select
                required
                aria-label="Default select example"
                name="lgaResidence2"
               
                onChange={handleLgaChange2}
                className="shadow-none form-select"
              >
                <option value="">Select LGA</option>
                {lgas2.map((it, i) => {
                  return (
                    <option value={it.id} key={it.id}>
                      {it.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
             
            <Col md={4} lg={4}>
              <Form.Label className="text-left">Select center.</Form.Label>
              <Select
               ref={selectInputRef2}
                isMulti   
                options={wards2}
                getOptionLabel={(e) => e.name}     
                getOptionValue={(e) => e.id}    
                onChange={handleMultiSelect2}   
                closeMenuOnSelect={false}   
                isValidNewOption={isValidNewOption} 
                components={{ Menu }}
                placeholder={'select center'}
              />
              
            </Col> 
 


              </Row> */}






{
  usertype ===USER_TYPE.AGENT &&
  <Row>
  <Col md={4} lg={4}>
      <Form.Label className="text-left">
        Select Preferred State Of Assignment
      </Form.Label>
      <Form.Select
        disabled
        aria-label="Default select example"
        name="userResidenceState"
      value={preState}
        // onChange={handleStateChange}
        className="shadow-none form-select"
      >
        {lg.map((it, i) => {
          return (
            <option key={it.id} 
            value={preState} 
            >
              {preName}
            </option>
          );
        })}                     
      </Form.Select>
    </Col>

    <Col md={4} lg={4}>
      <Form.Label className="text-left">
        Select  LGA 
      </Form.Label>
      <Form.Select
        // required
        disabled
        aria-label="Default select example"
        name="lgaResidence"
       
        // onChange={handleLgaChange}
        className="shadow-none form-select"
      >
      
        {lgas2.map((it, i) => {                
          return (
            <option value={preLga} key={it.id}>
              {preNameLga}
            </option>
          );
        })}
      </Form.Select>
    </Col>
    
    <Col md={4} lg={4}>
      <Form.Label className="text-left">Select ward.</Form.Label>
      <Select
       ref={selectInputRef}
        isMulti
        options={wards2}
        getOptionLabel={(e) => e.name}
        getOptionValue={(e) => e.id}
        onChange={handleMultiSelect}
        closeMenuOnSelect={false}  
        isValidNewOption={isValidNewOption} 
        components={{ Menu }}
        placeholder={'select ward'}
           value={
               wards2.find((element) => element.id === ward)  
                }
      />  
    </Col> 

      </Row>
}



                 {  usertype ===USER_TYPE.STAFF &&
                     <Row>
          <Col md={4} lg={4}>
              <Form.Label className="text-left">
                Select Preferred State Of Assignment
              </Form.Label>
              <Form.Select
                required
                aria-label="Default select example"
                name="userResidenceState"
              
                onChange={handleStateChange}
                className="shadow-none form-select"
              >
                <option value="">Select State </option>
                {lg.map((it, i) => {
                  return (
                    <option key={it.id} value={it.id}>
                      {it.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>

            <Col md={4} lg={4}>
              <Form.Label className="text-left">
                Select  LGA 
              </Form.Label>
              <Form.Select
                required
                aria-label="Default select example"
                name="lgaResidence"
               
                onChange={handleLgaChange}
                className="shadow-none form-select"
              >
                <option value="">Select LGA</option>
                {lgas.map((it, i) => {
                  return (
                    <option value={it.id} key={it.id}>
                      {it.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
            
            <Col md={4} lg={4}>
              <Form.Label className="text-left">Select ward.</Form.Label>
              <Select
               ref={selectInputRef}
                isMulti
                options={wards}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.id}
                onChange={handleMultiSelect}
                closeMenuOnSelect={false}  
                isValidNewOption={isValidNewOption} 
                components={{ Menu }}
                // value={ward}
                // value={
                //   wards &&
                //   wards.map((it) => {
                //     return { id: ward, name: it.name };
                //   })
                // }
                placeholder={'select ward'}
              />  
            </Col> 

              </Row>
}
              <Row>
            <Col md={12} lg={12} className="pt-2">
              <Form.Group className="mb-3" controlId="formBasicEText">
                <Form.Label className="text-left"> Usual Place Of Residence</Form.Label>
                <div className="map-cont">
                  <Map />
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            {!editRes && 
            
            <Col md={3} lg={3} className="pt-2 ms-auto">
            <button className="redz my-btn btn-block mt-4" disabled={loadingSave || loadingSubmit} onClick={handleSave}>
           {loadingSave&& <Spinner
        as="span"
        animation="grow"
        size="sm"                  
        role="status"
        aria-hidden="true"
        className='mx-2'
      />
           }
       SAVE AND CLOSE APPLICATION
             
            </button>
          </Col>
            }
       
          {/* <Col md={4} lg={4} className="pt-2">
              <button   className="my-btn btn-block btn-clear mt-4" onClick={()=>navigate(-1)}>Back</button>
            </Col> */}
            <Col md={4} lg={4} className="pt-2">
              <button type='submit' className="my-btn btn-block mt-4" disabled={loadingSubmit || loadingSave} onClick={handleSubmit}> {
                  loadingSubmit &&
              <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
          className='mx-2'
        />}{editRes?'UPDATE':'NEXT'}</button>
            </Col>
            </Row>
              </div>
</div>
}
    </Wrapper>
  )
}

const Wrapper = styled.section`
  padding: 1.6rem 0;
  text-transform: uppercase;
  background-image:
    linear-gradient(to right,  rgba(245, 246, 252, 0.92), 
    rgba(245, 246, 252, 0.82)),
    url(${pic2});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`
;
export default Residence