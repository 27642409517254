import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { handleChange, handleStChange } from "../redux/userSlice";
// import axios from "axios";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
// import makeAnimated from 'react-select/animated';
import CreatableSelect from "react-select/creatable";
import Title from "../components/Title";
import pic2 from "../assets/npc/water_5.png";
import { toast } from "react-toastify";
import Datetime from "react-datetime";
import {Spinner} from "react-bootstrap";
import { components } from "react-select";
import Progress from "../components/Progress";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import authFetch from "../axios";
import { EDIT_TYPE, STEP, SUCCESS_STATUS_DIGIT } from "../utils";


// .........testing.......
// import { components } from "react-select";
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
const BioData = () => {
  const navigate = useNavigate();
  const isValidDate = (current) => {
    const year = current.year();
    return year >= 1957 && year <= 2004;
  }; 

  // const animatedComponents = makeAnimated();
  const options = [
    { value: "English", label: "English" },
    { value: "Yoruba", label: "Yoruba" },
    { value: "Hausa", label: "Hausa" },
    { value: "Igbo", label: "Igbo" },
    { value: "Fulfude", label: "Fulfude" },
    { value: "Bassa", label: "Bassa" },
    { value: "Efik", label: "Efik" },
    { value: "Urhobo", label: "Urhobo" },
    { value: "Kanuri", label: "Kanuri" },
    { value: "Gbagi", label: "Gbagi" }

  ];

  // const sel = useRef(null);

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const {
    userState,
    lga,
    surname,
    firstname,
    midname,
    gender,
    date,
    // userStateDet,
    // lgaDet,
    criminal,
    computer,
    language,

    challenge,
    kinname,
    kinphone,
    kinrel,
accessCode,
    otherLang,
    editBio,
  } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  // const [userState, setUserState] = useState("");

  const [lg, setLg] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  // eslint-disable-next-line
  const [loadingSave, setLoadingSave] = useState(false);
  const [showDesc, setShowDesc] = useState(false);
  const [lgas, setLga] = useState([]);
  // const [wards, setWards] = useState([]);
  // const bearerToken = sessionStorage.getItem("userToken");
    const pattern = /^(09|08|07)/;

  const handleSubmit = async () => {
    // sel.current.removeAttribute("disabled");
    sessionStorage.setItem('fname',firstname)
    sessionStorage.setItem('lname',surname)
  
    console.log(accessCode);
    if(!accessCode || !firstname ||!surname ||!date ||!gender ||!userState ||!lga || !language  ||!criminal || !kinname ||!kinphone ){
      toast.error('fill all inputs before saving', {
        position: "top-center",
      });
      return;
    }


    // if(!kinphone.trim().startsWith('09') || !kinphone.trim().startsWith('08') || !kinphone.trim().startsWith('07')){
    //   console.log(typeof(kinphone))
    //   toast.error('use a valid next of kin phone number', {
    //     position: "top-center",
    //   });
    //   return;
    // }
    if(!pattern.test(kinphone)){
      toast.error('use a valid next of kin phone number', {
        position: "top-center",
      });
      return;
    }
    if (editBio) {
       setLoadingSubmit(true)
        try {
          const jsonData={
          firstName:firstname,
          lastName:surname,
          middleName:midname,
          dob: date,
          gender:gender,
          state: userState,
          lga,
          physical_challenge: challenge,
          computer_skill:computer,
          language,
          other_language: otherLang,
          criminal_record: criminal,
          next_kin_name: kinname,
          next_kin_phone: kinphone,
          next_kin_relationship: kinrel,
          edit: EDIT_TYPE.EDIT
            }

          var config1 = {
            method: "post",
            url: "/applicant/update-bio-data",
            data: jsonData,
          };
          const { data } = await authFetch(config1);
          console.log(data.palliative);
         if(data.palliative.status===SUCCESS_STATUS_DIGIT.SUCCESS){
          toast.success("Application sent successfully", {
            position: "top-center",
          });
          setLoadingSubmit(false)
          dispatch(handleChange({ name: "editBio", value: false }));
          sessionStorage.setItem('editBio',false);
      navigate("/prev");
         }

         else if(data.palliative.status === SUCCESS_STATUS_DIGIT.FAILURE){
          toast.error(data.palliative.message, {
            position: "top-center",
          });
          setLoadingSubmit(false)
         }
        }catch{
          toast.error("please retry", {
            position: "top-left",
          });
          setLoadingSubmit(false)
        }
        // sessionStorage.setItem("bioDataInfo", JSON.stringify(bio_data));
       
    } else {
      if (!date) {
        toast.error("please fill in date", {
          position: "top-center",
        });
        return;
      }
      if (!language) {
        toast.error("please fill in language", {
          position: "top-center",
        });
        return;
      } else {
       setLoadingSubmit(true)
        try {
          const jsonData2={
            firstName:firstname,
            lastName:surname,
            middleName:midname,
            dob: date,
            gender:gender,
            state: userState,
            lga,
            physical_challenge: challenge,
            computer_skill:computer,
            language,
            other_language: otherLang,
            criminal_record: criminal,
            next_kin_name: kinname,
            next_kin_phone: kinphone,
            next_kin_relationship: kinrel,
            edit:EDIT_TYPE.NEW // mean edit, empty or zero mean new record
                
              }

         
          var config = {
            method: "post",
            url: "/applicant/update-bio-data",
            data: jsonData2,
          };
          const { data } = await authFetch(config);
          console.log(data.palliative);
         if(data.palliative.status===SUCCESS_STATUS_DIGIT.SUCCESS){
          toast.success("Application sent successfully", {
            position: "top-center",
          });
          setLoadingSubmit(false)
          navigate("/residence");
         }

         else if(data.palliative.status === SUCCESS_STATUS_DIGIT.FAILURE){
          toast.error(data.palliative.message, {
            position: "top-center",
          });
          setLoadingSubmit(false)
         }
        }catch{
          toast.error("please retry", {
            position: "top-left",
          });
          setLoadingSubmit(false)
        }
        // sessionStorage.setItem("bioDataInfo", JSON.stringify(bio_data));
       
      }
    }
  };




// eslint-disable-next-line
  const handleSave = async (e) => {
    // sel.current.removeAttribute("disabled");
    sessionStorage.setItem('fname',firstname)
    sessionStorage.setItem('lname',surname)
if(!accessCode || !firstname ||!surname ||!date ||!gender ||!userState ||!lga  || !language  ||!criminal || !kinname ||!kinphone ){
  toast.error('fill all inputs before saving', {
    position: "top-center",
  });
  return;
}
    if(!pattern.test(kinphone)){
      toast.error('use a valid next of kin phone number', {
        position: "top-center",
      });
      return;
    }
    setLoadingSave(true);
    try {
      const jsonData3={
        firstName:firstname,
        lastName:surname,
        middleName:midname,
        dob: date,
        gender:gender,
        state: userState,
        lga,
        physical_challenge: challenge,
        computer_skill:computer,
        language,
        other_language: otherLang,
        criminal_record: criminal,
        next_kin_name: kinname,
        next_kin_phone: kinphone,
        next_kin_relationship: kinrel,
        edit:EDIT_TYPE.NEW
          }
      var config = {
        method: "post",
        url: "/applicant/update-bio-data",
        data: jsonData3,
      };
      const { data } = await authFetch(config);
      console.log(data.palliative);
     if(data.palliative.status=== SUCCESS_STATUS_DIGIT.SUCCESS){
      toast.success("Application sent successfully", {
        position: "top-center",
      });
      setLoadingSave(false)
      sessionStorage.removeItem("dataMine");
    sessionStorage.clear();
    navigate("/");
     }

     else if(data.palliative.status === SUCCESS_STATUS_DIGIT.FAILURE){
      toast.error(data.palliative.message, {
        position: "top-center",
      });
      setLoadingSave(false)
     }
    }catch{
      toast.error("please retry", {
        position: "top-left",
      });
      setLoadingSave(false)
    }
  };

  const handleInp = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(handleChange({ name, value }));
  };


  const handleCriminal=(e)=>{
    // const name = e.target.name;
    const value = e.target.value;
    if(value ==='yes'){
      setShowDesc(true)
      dispatch(handleChange({name: 'criminal',value:''}));
    }
    else if(value ==='no'){
      setShowDesc(false)
      dispatch(handleChange({name: 'criminal',value:'no'}));
    }
  }

  // const handleMultiSelect = (selectedOption) => {
  //   console.log(selectedOption);
  //   const news = selectedOption.map((it, i) => {
  //     return it.value;
  //   });
  //   const name = "language";
  //   const newz=news.join(',')
  //   dispatch(handleChange({ name, value:newz }));
  //   console.log(news,newz);
  // };
   


  const handleMultiiSelect = (selectedOption) => {
    console.log(selectedOption);
    const news = selectedOption.map((it, i) => {
      return it.value;
    });
    const name = "language";
    const newz = news.join(",");
    dispatch(handleChange({ name, value: newz }));
    console.log(news, newz);
  };

  // const handleMultiAsyncSelect = (selectedOption) => {
  //   const news = selectedOption.map((it, i) => {
  //     return it.id;
  //   });
  //   const name = "ward";
  //   // console.log(news);
  //   dispatch(handleChange({ name, news }));
  // };
  const handleStateChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const tx = e.target.options[e.target.selectedIndex].text;
    dispatch(handleChange({ name, value }));
    dispatch(handleStChange({ name: "userStateDet", value: tx }));
    console.log(userState);
   

    var config = {
      method: "get",
      url: `resources/lga?stateId=${value}`,
    };
    const { data } = await authFetch(config);
    // console.log(data.npc);
    setLga(data.palliative);
    // setUserState(e.target.value);
    // console.log(userState);
  };

  const handleC = (newValue) => {
    console.log(newValue);
    const news = newValue.map((it, i) => {
      return it.value;
    });
    const name = "otherLang";
    const newz = news.join(",");
    dispatch(handleChange({ name, value: newz }));
  };
  
  const handleLgaChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const lgtx = e.target.options[e.target.selectedIndex].text;
    dispatch(handleChange({ name, value }));
    dispatch(handleStChange({ name: "lgaDet", value: lgtx }));
    // console.log(userState);
   

    var config = {
      method: "get",
      url: `resources/ward?lgaId=${value}`,
    };
    const { data } = await authFetch(config);
    console.log(data.palliative);
    // setWards(data.npc);
  };

  // console.log(language,surname);


  useEffect(() => {
    const getDetails=async ()=>{
      // setPreLoading(true);
      try {
      
        var config = {
          method: "get",
          url: "/auth/retrive-data",

       
        };
        const { data } = await authFetch(config);
        console.log(data.palliative[0])
        if(data.palliative.step < STEP.TEST && data.palliative.test_passed ===false){
  navigate(-1);
        }
        // sessionStorage.setItem('dataMine',JSON.stringify(data.palliative[0]))
        // setRez(data.palliative[0])
        // setPreLoading(false);
      } catch (error) {
        console.log(error);
        // setPreLoading(false);
      }
    }
    getDetails();
  }, [navigate])
  useEffect(() => {

    const fetchState = async () => {
      try {
        // Replace 'YOUR_BEARER_TOKEN' with your actual bearer token
    
        const res = await authFetch.get(
          "resources/states",
        );
    
        setLg(res.data.palliative);
      } catch (error) {
        console.log(error);
      }



    };
    fetchState();
  }, []);
  useEffect(() => {
    const typ = sessionStorage.getItem("user-type");
    if (!typ) {
     return
    }
    Swal.fire({
      title: `please save your application ID`,
      // text: 'success',
      icon: 'success',
      // text: `please save your application ID`,
      text: `${accessCode} Your ID can also be copied on the header`,
      confirmButtonColor: '#0b6916',
confirmButtonText: 'okay'
    })
  }, [accessCode]);
  return (
    <Wrapper>
      <div className="container">
        <Title title="personal information" />
        <div className="">
          <h5 className="text-center pt-2"> bio data</h5>
<Progress active={1}/>
          <Row>
            <Col md={4} lg={4}>
              <Form.Group className="mb-3" controlId="formBasicEText">
                <Form.Label className="text-left required">Surname</Form.Label>
                <Form.Control
                readOnly={true}
                  required
                  type="text"
                  placeholder="Enter Surname"
                  className="form_input shadow-none"
                  name="surname"
                  value={surname}
                  onChange={handleInp}
                  style={{
                    background:'#f5f5f5'
                  }}
                />
              </Form.Group>
            </Col>
                                                   
            <Col md={4} lg={4}>
              <Form.Group className="mb-3" controlId="formBasicEText">
                <Form.Label className="text-left required">First Name</Form.Label>
                <Form.Control
                 readOnly={true}
                  required
                  type="text"           
                  placeholder="Enter Firstname"
                  name="firstname"
                  className="form_input shadow-none"
                  value={firstname}
                  maxLength={40}
                  onChange={handleInp}
                  style={{
                    background:'#f5f5f5'
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={4} lg={4}>
              <Form.Group className="mb-3" controlId="formBasicEText">
                <Form.Label className="text-left">Middle name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Middle name"
                  name="midname"
                  className="form_input shadow-none"
                  value={midname}
                  maxLength={40}
                  onChange={(e)=>{
                    const inputText = e.target.value;
                    if (/^[A-Za-z\s]*$/.test(inputText) || inputText === '') {
                      // Only allow alphabetic characters and spaces, or an empty string
                      handleInp(e);
                    }
                  }
                   
                  }
                  
                  
                  // onKeyDown={(e) => {
                  //   const inputText = e.target.value;
                  //   console.log(e.key)
                  //   if (!/^[A-Za-z\s]+$/.test(inputText) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  //     e.preventDefault();
                  //   }
                  // }}
                  // onKeyDown={(e)=>{
                  //   const inputText = e.target.value;
                  //   console.log(e.KeyCode);
                  //   console.log(inputText)
    // if (!/^[A-Za-z\s]+$/.test(inputText) && e.key !== 'Backspace' && e.key !== 'Delete') {
    //   // Prevent input of non-alphabetic characters (except Backspace and Delete keys)
    //   e.preventDefault();
    // }

    // else{
    //   return null;
    // }
                  // }}
                  // onKeyDown={`return /[a-z]/i.test(event.key)`}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4} lg={4}>
              <Form.Group className="mb-3" controlId="formBasicEText">
                <Form.Label className="text-left required">
                  Date of Birth(You must be between 18-50 years.)
                </Form.Label>

                {date === "" ? (
                  <Datetime
                    inputProps={{ placeholder: "DATE OF BIRTH" }}
                    required={true}
                    isValidDate={isValidDate}
                    onChange={(date) => {
                      //         



                      const selectedDate = new Date(date._d); // pass in date param here
                      const formattedDate = `${
                        selectedDate.getMonth() + 1
                      }/${selectedDate.getDate()}/${selectedDate.getFullYear()}`;
                      // console.log(formattedDate);
                      let name = "date";
                      let value = formattedDate;
                      dispatch(handleChange({ name, value }));
                    }}
                    timeFormat={false}
                  />
                ) : (
                  <Form.Control
                  readOnly={true}
                    required
                    type="text"
                    placeholder="DOB"
                    name="date"
                    value={date}
                    className="form_input shadow-none"
                    onChange={handleInp}
                  />
                )}
              </Form.Group>
            </Col>

            <Col md={4} lg={4}>
              <Form.Label className="text-left required">Select Gender</Form.Label>
              <Form.Select
              disabled
              // ref={sel}
              // readOnly={true}
                // required
                aria-label="Default select example"
                name="gender"
                value={gender}
                onChange={handleInp}
                className="shadow-none form-select"
              >
                <option value="">Select Gender</option>
                <option value="m">male</option>
                <option value="f">female</option>
              </Form.Select>
            </Col>
            <Col md={4} lg={4}>
              <Form.Label className="text-left required">
                Select State Of Origin
              </Form.Label>
              <Form.Select
                required
                aria-label="Default select example"
                name="userState"
                // value={userState}
                onChange={handleStateChange}
                className="shadow-none form-select"
              >
                <option value="">Select State</option>
                {lg.map((it, i) => {
                  return (
                    <option key={it.id} value={it.id}>
                      {it.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
          </Row>
          <Row>
            <Col md={4} lg={4}>
              <Form.Label className="text-left required">
                Select LGA of Origin
              </Form.Label>
              <Form.Select
                required
                aria-label="Default select example"
                name="lga"
                // value={lga}
                onChange={handleLgaChange}
                className="shadow-none form-select"
              >
                <option value="">Select LGA</option>
                {lgas.map((it, i) => {
                  return (
                    <option value={it.id} key={i}>
                      {it.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
            <Col md={4} lg={4}>
              <Form.Label className="text-left">Difficulties In Performing Any Of The Following:</Form.Label>
              <Form.Select
               
                aria-label="Default select example"
                name="challenge"
                value={challenge}
                onChange={handleInp}
                className="shadow-none form-select"
              >
                <option value="">Select</option>

                <option value="no">No</option>
               
                <option value="sight(seeing)">Sight(Seeing)</option>
                <option value="physical(Walking Or Climbing Stairs)">Physical(Walking Or Climbing Stairs)</option>
                <option value="cognitive(Remembering Or Concentrating)">Cognitive(Remembering Or Concentrating)</option>
                <option value="self Care(Washing All Over Or Dressing)">Self Care(Washing All Over Or Dressing)</option>
                <option value="Speech(Speaking)">Speech(Speaking)</option>
              </Form.Select>
            </Col>
            <Col md={4} lg={4} className="mb-3">
              <Form.Label className="text-left">
                Computer Proficiency
              </Form.Label>
              <Form.Select
               
                aria-label="Default select example"
                name="computer"
                value={computer}
                onChange={handleInp}
                className="shadow-none form-select"
              >
                <option value="">Select Proficiency</option>
                <option value="basic">Basic</option>
                <option value="intermediate">Intermediate</option>
                <option value="advanced">Advanced</option>
              </Form.Select>
            </Col>
            {/* <Col md={4} lg={4}>
              <Form.Label className="text-left">Select Ward</Form.Label>
              <Select
                isMulti
                options={wards}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.id}
                onChange={handleMultiAsyncSelect}
              />
              
            </Col> */}

            {/* <Col md={4} lg={4}>
              <Form.Group className="mb-3" controlId="formBasicEText">
                <Form.Label className="text-left">Phone number</Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="Enter phone number"
                  name="phone"
                  className="form_input shadow-none"
                  maxLength="15"
                  value={phone}
                  onChange={handleInp}
                />
              </Form.Group>
            </Col> */}
          </Row>
          <Row>
            <Col md={4} lg={4} className="mb-3">
              <Form.Label className="text-left required">
                Criminal Case Conviction
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={handleCriminal}

                name='crimeChoice'
                className="shadow-none form-select"
              >
                <option value="">select Conviction</option>
                <option value="yes">yes</option>
                <option value="no">no</option>
              </Form.Select>
            </Col>
      
            {
              showDesc &&
                <Col md={4} lg={4} className="mb-3">
                <Form.Label className="text-left required">
                 Describe Crime
                </Form.Label>
                <Form.Control
                // readOnly={true}
                  // required
                  type="text"
                  placeholder="Describe Crime"
                  className="form_input shadow-none"
                  name="criminal"
                  value={criminal}

                
                  onChange={(e)=>{
                    const inputText = e.target.value;
                    if(inputText.length >250){
return;
                    }
                    if (/^[A-Za-z\s]*$/.test(inputText) || inputText === '') {
                      // Only allow alphabetic characters and spaces, or an empty string
                      handleInp(e);
                    }
                  }
                }
                />
              </Col>
            }

            <Col md={4} lg={4} className="mb-3">
              <Form.Label className="text-left required">
                 Language Spoken well(multiple)
              </Form.Label>
              <Select
                isMulti
                options={options}
                // components={animatedComponents}
                hideSelectedOptions={false}
                components={{
                  Option,
                }}
                className="shadow-none form-multi"
                closeMenuOnSelect={false}
                onChange={handleMultiiSelect}
                value={
                  language &&
                  language.split(",").map((it) => {
                    return { value: it, label: it };
                  })
                }
              />

              {/* <ReactMultiSelectCheckboxes options={options} onChange={handleMultiiSelect}  style={{width:'100%'}}  className=""  value={language&&language.split(',').map((it)=>{
return  { value: it, label: it };
                })} /> */}
            </Col>
            <Col md={4} lg={4} className="mb-3">
              <Form.Label className="text-left">Other spoken languages</Form.Label>
              <CreatableSelect
                isMulti
                onChange={handleC}
                formatCreateLabel={(inputText) => `Add "${inputText}"`}
                placeholder="Type Other Languages"
                value={
                  otherLang &&
                  otherLang.split(",").map((it) => {
                    return { value: it, label: it };
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={4} lg={4}>
              <Form.Group className="mb-3" controlId="formBasicEText">
                <Form.Label className="text-left required">Next of kin name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter next of kin name"
                  name="kinname"
                  className="form_input shadow-none"
                  value={kinname}
                  onChange={(e)=>{
                    const inputText = e.target.value;
                    if (/^[A-Za-z\s]*$/.test(inputText) || inputText === '') {
                      // Only allow alphabetic characters and spaces, or an empty string
                      handleInp(e);
                    }
                  }
                }
                  // onChange={handleInp}
                />
              </Form.Group>
            </Col>

            <Col md={4} lg={4}>
              <Form.Group className="mb-3" controlId="formBasicEText">
                <Form.Label className="text-left">
                  Next of kin relationship to Applicant
                </Form.Label>
                <Form.Control
                
                  type="text"
                  placeholder="Enter next of kin relationship e.g father"
                  name="kinrel"
                  className="form_input shadow-none"
                  value={kinrel}
               
                  onChange={(e)=>{
                    const inputText = e.target.value;
                    if (/^[A-Za-z\s]*$/.test(inputText) || inputText === '') {
                      // Only allow alphabetic characters and spaces, or an empty string
                      handleInp(e);
                    }
                  }
                }
                />
              </Form.Group>
            </Col>

            {/* <Col md={4} lg={4}>
              <Form.Group className="mb-3" controlId="formBasicEText">
                <Form.Label className="text-left">
                  Next of kin address
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter next of kin address"
                  name="kinaddy"
                  className="form_input shadow-none"
                  value={kinaddy}
                  onChange={handleInp}
                />
              </Form.Group>
            </Col> */}

                      
            <Col md={4} lg={4}>
              <Form.Group className="mb-3" controlId="formBasicEText">
                <Form.Label className="text-left required">Next of kin phone</Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="Enter next of kin phone"
                  name="kinphone"
                  className="form_input shadow-none"
                  value={kinphone}
                  onChange={handleInp}
                  onInput={(e) => e.target.value = e.target.value.slice(0, 11)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            {!editBio &&
             <Col md={3} lg={3} className="pt-2 ms-auto">
             <button className="redz my-btn btn-block mt-4" disabled={loadingSave || loadingSubmit} onClick={handleSave}>
             {loadingSave&&<Spinner
         as="span"
         animation="grow"
         size="sm"
         role="status"
         aria-hidden="true"
         className='mx-2'
       />
             }
        SAVE AND CLOSE APPLICATION
              
             </button>
           </Col>
            }
         
            <Col md={3} lg={3} className="pt-2 ms-auto">
              <button className="my-btn btn-block mt-4" disabled={loadingSubmit|| loadingSave}  onClick={handleSubmit}>
                {
                  loadingSubmit &&
              <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
          className='mx-2'
        />} 
              {editBio ? "UPDATE" : "NEXT"}
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 1.6rem 0;
  text-transform: uppercase;
  background-image: linear-gradient(
      to right,
      rgba(245, 246, 252, 0.92),
      rgba(245, 246, 252, 0.82)
    ),
    url(${pic2});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
export default BioData;
