import React,{useEffect,useState, useRef} from 'react'
import styled from "styled-components";
import { Form,Modal,Spinner } from "react-bootstrap";
// import axios from "axios";
import { toast } from "react-toastify";
import pic from "../assets/npc/water_5.png";
import {  useNavigate } from "react-router-dom";
import authFetch from '../axios';
import { STEP } from '../utils';
import Swal from 'sweetalert2/dist/sweetalert2';
// import Countdown from 'react-countdown';
// import Swal from 'sweetalert2/dist/sweetalert2.js';
const Test = () => {
  const btnnn = useRef(null);
  const btnn = useRef(null);
    const navigate = useNavigate();
    const [questions,setQue] = useState([]);
    const [ans1, setAns1] = useState("");
    const [staff, setStaff] = useState(false);
    const [ans2, setAns2] = useState("");
    const [ans3, setAns3] = useState("");
    const [ans4, setAns4] = useState("");
    const [ans5, setAns5] = useState("");
    const [ans6, setAns6] = useState("");
    const [ans7, setAns7] = useState("");
    const [ans8, setAns8] = useState("");
    const [ans9, setAns9] = useState("");
    const [ans10, setAns10] = useState("");
      

    // const [time, setTime] = useState("");
    const [loading, setLoading] = useState(false);
    const [minutes, setMinutes] = useState(2);
    const [seconds, setSeconds] = useState(0);
     
    // const Timer = ()=>{
    //  return <Countdown  date={Date.now() + 300000} onComplete={async()=>{
    //   try {
    //                       var formdata = new FormData();
    //                       formdata.append("access-code", dqmCode);
    //                       formdata.append("questionid1", questions[0]?.questionid);
    //                       formdata.append("answer1", ans1);
    //                       formdata.append("questionid2", questions[1]?.questionid);
    //                       formdata.append("answer2", ans2);
    //                       formdata.append("questionid3", questions[2]?.questionid);
    //                       formdata.append("answer3", ans3);
    //                       // formdata.append("access-code", dqmCode);
                    
    //                       var config = {
    //                         method: "post",
    //                         url: "https://api.verxid.site/npc/unicef/update-questionAnswered",
    //                         headers: {
    //                           Authorization: "Basic YmFybmtzZm9ydGUtbmltYzowbmx5YmFybmtzMTIz",
    //                         },
    //                         data: formdata,
    //                       };
                       
    //                       const { data } = await axios(config);
    //                       // console.log(data)
    //                       setLoading(false);
    //                       if(data.npc.test_status === true){
    //                         // toast.success("Test passed.", {
    //                         //   position: "top-left",
    //                         // });
    //                         Swal.fire({
    //                           title: `Test passed with a score of ${data.npc.test_score}`,
    //                           text: 'success',
    //                           icon: 'success',
                    
    //                           confirmButtonColor: '#0b6916',
    //                    confirmButtonText: 'okaye'
    //                         }).then((result) => {
    //                               if(result.value){
    //                                 window.location.href='/bio-data';
    //                                 sessionStorage.setItem('testPassed',true);
    //                               }
    //                             })
    //                       }
    //                       else if(data.npc.test_status === false){
    //                           toast.error("test failed.",{
    //                             position: "top-left",
    //                           });
    //                         sessionStorage.clear();
    //                         navigate('/');
    //                       }
    //                     } catch (error) {
    //                     //   setLoading(false);
    //                       toast.error("please retry.", {
    //                         position: "top-left",
    //                       });
    //                     }
                      
    //  }}/>
    // }
  //   const CountDown = (duration)=>{
  //     if (!isNaN(duration)) {
  //         var timer = duration, minutes, seconds;
          
  //       var interVal=  setInterval(async function () {
  //             minutes = parseInt(timer / 60, 10);
  //             seconds = parseInt(timer % 60, 10);

  //             minutes = minutes < 10 ? "0" + minutes : minutes;
  //             seconds = seconds < 10 ? "0" + seconds : seconds;

  //             setTime(`${minutes}m : ${seconds}s`);
  //             if (--timer < 0) {
  //                 timer = duration;
  //                 // submit
  //                 try {
  //                   var formdata = new FormData();
  //                   formdata.append("access-code", dqmCode);
  //                   formdata.append("questionid1", questions[0]?.questionid);
  //                   formdata.append("answer1", ans1);
  //                   formdata.append("questionid2", questions[1]?.questionid);
  //                   formdata.append("answer2", ans2);
  //                   formdata.append("questionid3", questions[2]?.questionid);
  //                   formdata.append("answer3", ans3);
  //                   // formdata.append("access-code", dqmCode);
              
  //                   var config = {
  //                     method: "post",
  //                     url: "https://api.verxid.site/npc/unicef/update-questionAnswered",
  //                     headers: {
  //                       Authorization: "Basic YmFybmtzZm9ydGUtbmltYzowbmx5YmFybmtzMTIz",
  //                     },
  //                     data: formdata,
  //                   };
                 
  //                   const { data } = await axios(config);
  //                   // console.log(data)
  //                   setLoading(false);
  //                   if(data.npc.test_status === true){
  //                     toast.success("Test passed.", {
  //                       position: "top-left",
  //                     });
  //                     window.location.href='/bio-data';
  //                     sessionStorage.setItem('testPassed',true);
  //                   }
  //                   else if(data.npc.test_status === false){
  //                       toast.error("testfailed.",{
  //                         position: "top-left",
  //                       });
  //                     sessionStorage.clear();
  //                     navigate('/');
  //                   }
  //                 } catch (error) {
  //                 //   setLoading(false);
  //                   toast.error("please retry.", {
  //                     position: "top-left",
  //                   });
  //                 }
  //               //  $('#display').empty();
  //                clearInterval(interVal)
  //             }
  //             },1000);
  //     }
  // }
    // const [dqmQuestion,setQuestion] = useState({});
// const dqmCode = sessionStorage.getItem('access_code')
// const bearerToken = sessionStorage.getItem("userToken");
    const handleQuestionStart=async(e)=>{
        e.preventDefault();
        // if(!ans1 || !ans2 || !ans3 ||!ans4 || !ans5 || !ans6 || !ans7 || !ans8 || !ans9 || !ans10 ) {
        //   toast.error("please attempt all questions.",{
        //     position: "top-left",
        //   });
        //   return;
        // }

        setStaff(false);
        setLoading(true);
        try {
         const data7= {
            answers: [
              {question_id: questions[0]?.questionid, answer: ans1},
              {question_id: questions[1]?.questionid, answer: ans2},
              {question_id: questions[2]?.questionid, answer: ans3},
              {question_id: questions[3]?.questionid, answer:ans4},
              {question_id: questions[4]?.questionid, answer: ans5},
              {question_id: questions[5]?.questionid, answer:ans6},
              {question_id: questions[6]?.questionid, answer: ans7},
              {question_id: questions[7]?.questionid, answer: ans8},
              {question_id: questions[8]?.questionid, answer: ans9},
              {question_id: questions[9]?.questionid, answer: ans10}
            ]
          }

          var config = {
            method: "post",
            url: "/applicant/update-answers",
       
            data: data7,
          };
          const { data } = await authFetch(config);
          console.log(data)
          setLoading(false);
          if(data.palliative.test_status === true){
            Swal.fire({    
              title: `Congratulations!!!`,
              // text: 'success',
              icon: 'success',
              // text: `please save your application ID`,
              text: `Test passed with a score of ${data.palliative.test_score}`,
              confirmButtonColor: '#0b6916',
        confirmButtonText: 'Start Application'
            }).then((result) => {
              if(result.value){
                window.location.href='/bio-data';
                sessionStorage.setItem('testPassed',true);
              }
            })
//  toast.success(`Test passed with a score of ${data.palliative.test_score}`, { position: "top-left",});
//  window.location.href='/bio-data';
// sessionStorage.setItem('testPassed',true);
                                  
          }
          else if(data.palliative.test_status === false){
              toast.error(`Test failed with a score of ${data.palliative.test_score}.`,{
                position: "top-left"
              });
            sessionStorage.clear();
            navigate('/');
          }
        } catch (error) {
        //   setLoading(false);
          toast.error("please retry.", {
            position: "top-left",
          });
        }
      }

      const changeAns = (ans,itemNumber)=>{
        console.log(ans,itemNumber)
if(itemNumber===1){
setAns1(ans)
return;
}
if(itemNumber===2){
    setAns2(ans)
    return
    }
    if(itemNumber===3){
        setAns3(ans)
        return
        }
        if(itemNumber===4){
          setAns4(ans)
          return
          }
          if(itemNumber===5){
            setAns5(ans)
            return
            }
            if(itemNumber===6){
              setAns6(ans)
              return
              }
              if(itemNumber===7){
                setAns7(ans)
                return
                }
                if(itemNumber===8){
                  setAns8(ans)
                  return
                  }
                  if(itemNumber===9){
                    setAns9(ans)
                    return
                    }
                    if(itemNumber===10){
                      setAns10(ans)
                      return
                      }
      }

      useEffect(() => {
        const getDetails=async ()=>{
          // setPreLoading(true);
          try {
          
            var config = {
              method: "get",
              url: "/auth/retrive-data",
            };
            const { data } = await authFetch(config);
            if(data.palliative.step >= STEP.TEST){
              sessionStorage.clear();
            }
            // setPreLoading(false);
          } catch (error) {
            console.log(error);
            // setPreLoading(false);
          }
        }
        getDetails();
      }, [])
    
      
    useEffect(() => {

                    
        const fetchState = async () => {
            // e.preventDefault();
            setLoading(true);
            try {
              var config = {
                method: "get",
                url: "/resources/questions",
            
               
              };

              const { data } = await authFetch(config);
              // console.log(data)
              setQue(data.palliative);
              setLoading(false);
              
            } catch (error) {
              setLoading(false);
              toast.error("please retry.", {
                position: "top-left",
              });
            }
        };
        fetchState();
      }, []);

      useEffect(() => {
        const interval = setInterval(async() => {
          if (seconds > 0) {
            setSeconds(seconds - 1);
          }
          if (seconds === 0) {
            if (minutes === 0) {
              // clearInterval(interval);
              btnnn.current.click();
              // handleQuestionStart()
            } else {
              setSeconds(59);
              setMinutes(minutes - 1);
            }
          }
        }, 2000);
      
        return () => {
          clearInterval(interval);
        };
      }, [seconds,minutes]);
      if(loading){
return <SpinnerWrap >
<Spinner/>
</SpinnerWrap>
      }
  return (
    <Wrapper className="nin-container">
    <div className="container">
      <div className="row">
        <div className="col-10 mx-auto">
        
        <h4 className="mt-5 mb-0 text-left"> Palliative Agent’s e-recruitment Assessment Test</h4>
        <p className="text-left">Correctly answer all the questions below. Each question carries 10marks</p>
        {/* <Timer/> */}
        <p style={{fontWeight:'bold',fontSize:'27px'}}>
          Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
          {seconds < 10 ? `0${seconds}` : seconds}
        </p>
      {
questions?.map((item,id)=>{
return <div  className="mt-3" key={id}>
<p className="">
 {id + 1}. {item.question}
</p>
<Form className="">
{
  item.options &&
  Object.values(item.options).map((item,i)=>{
    if(item===''){
return "";
    }
    return <Form.Group className="mb-3" controlId="formBasicCheckbox" key={i}>
    <Form.Check
      // required
      type="radio"
      label={item}
      className="shadow_none form_check"
      value={`option${i +1}`}
      name='ans'
      onChange={(e)=>changeAns(e.target.value,id+1)}
      style={{marginLeft:'13px'}}
    />
  </Form.Group>
    
  })
}

</Form>
</div>


})
  
}
<div className='' style={{display:'flex',alignItems:'center',justifyContent:'end'}}>
<button
              type="button"
              className="my-btn "
              style={{marginLeft:'auto'}}
        onClick={()=>setStaff(true)}
            >
              SUBMIT ANSWER
            </button>

            <button
              type="button"
              className="my-btn"
              style={{opacity:'0'}}
        onClick={handleQuestionStart}
        ref={btnnn}
            >
              SUBMIT ANSWER
            </button>
</div>
      
       </div>
       </div>
       </div>

       <Modal
        size="md"
        show={staff}
        onHide={() => 
         { 
          setStaff(false);
          // setSmShow(true);
         }
        }
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header className="modal-header" closeButton>
         
        </Modal.Header>
        <Modal.Body>
        <p style={{
          textAlign:'center'
        }}>Are you sure you want to submit this test?</p>
         
         
       
        </Modal.Body>
        <Modal.Footer>
          <button
              type="button"
              className="my-btn"
              // style={{marginLeft:'auto'}}
        onClick={handleQuestionStart}
        ref={btnn}
            >
              SUBMIT ANSWER
            </button>
        </Modal.Footer>
      </Modal>
       </Wrapper>
    
  )
}

const Wrapper = styled.section`
min-height:100vh ;
display: flex;
justify-content: center;
/* align-items: center; */
width: 100%;
background-image: url(${pic});
background-position: center;
background-repeat: no-repeat;
background-size: cover;
`;
const SpinnerWrap = styled.section`
min-height:100vh ;
display: flex;
justify-content: center;
align-items: center;
width: 100%;
background-image: url(${pic});
background-position: center;
background-repeat: no-repeat;
background-size: cover;
`;
export default Test