import React ,{useEffect, useState}from 'react'
// import Title from '../components/Title';
import { Form,Row,Col,InputGroup,FormControl,Button,Spinner } from 'react-bootstrap';
import pic2 from "../assets/npc/water_5.png";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { handleChange } from "../redux/userSlice";
// import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal  from 'sweetalert2/dist/sweetalert2';
import authFetch from '../axios';
import { EDIT_TYPE, STEP, SUCCESS_STATUS_DIGIT, USER_TYPE } from '../utils';
// import Progress from '../components/Progress';
const Contact = () => {
  
  const [ot,showOt] = useState(false);
  const [ot2,showOt2] = useState(false);
const [code,setCode] = useState("");
const [code2,setCode2] = useState("");
// eslint-disable-next-line   
const [ver1,setVer1] = useState(false);
const [ver2,setVer2] = useState(false);
const [loading, setLoading] = useState(false);
const [loadingSubmit, setLoadingSubmit] = useState(false);
// eslint-disable-next-line
const [loadingSave, setLoadingSave] = useState(false);
// eslint-disable-next-line

// const bearerToken = sessionStorage.getItem("userToken");
const userTypes= sessionStorage.getItem("user-type");
const handleOtpInp=(e)=>{
  if(e.target.value.length > 6 ){
return;
  }
    setCode(e.target.value)
}
const handleOtpInp2=(e)=>{
  if(e.target.value.length > 6 ){
    return;
      }
  setCode2(e.target.value)
}
    const navigate = useNavigate();
    const {
      editCon,
        phone,
        email
      } = useSelector((state) => state.user);
      const dispatch = useDispatch();



      const verPhone=async()=>{
        if(!email){
          Swal.fire({
            title: 'Error',
            text: 'email can not be empty',
            icon: 'error',
           
            confirmButtonColor: '#0b6916',
     confirmButtonText: 'Ok'
          })
          return;
        }

        // relaxed

        else if (userTypes === USER_TYPE.STAFF && !email.includes('nimc.gov.ng')){
            Swal.fire({
            title: 'Error',
            text: 'email is not NIMC verified',
            icon: 'error',
            confirmButtonColor: '#0b6916',
     confirmButtonText: 'Ok'
          })
          return;
        } 
        else{
          setLoading(true);
          try {
            
            const data1 = {email}

 var config = {
  method: "post",
  url: "/applicant/verify-email",
  data: data1,
};  
            // var config = {
            //     method: 'get',
            //     url: `https://api.verxid.site/pallative_recuirment/src/verifyEmail`,
            //     headers: { }
            //   };
              const { data } = await authFetch(config);
              if(data.palliative.status === SUCCESS_STATUS_DIGIT.SUCCESS){
                setLoading(false);
                Swal.fire({
                  title: ' Success',
                  text: 'check your email',
                  icon: 'success',
                 
                  confirmButtonColor: '#0b6916',
           confirmButtonText: 'Ok'
                })
                
showOt(true);
              }
              else if(data.palliative.status === SUCCESS_STATUS_DIGIT.FAILURE){
                setLoading(false);
                Swal.fire({
                  title: 'Error',
                  text: `${data.palliative.msg} || please retry`,
                  icon: 'error',
                  confirmButtonColor: '#0b6916',
           confirmButtonText: 'Ok'
                })
                showOt(false);
              }
          } catch (error) {
            setLoading(false);
              console.log(error)
              Swal.fire({
                title: 'Error',
                text: 'please try again',
                icon: 'error',
               
                confirmButtonColor: '#0b6916',
         confirmButtonText: 'Ok'
              })
          }
        }
      }




      const verPhone2=async()=>{
        if(!phone){
          Swal.fire({
            title: 'Error',
            text: 'phone can not be empty',
            icon: 'error',
           
            confirmButtonColor: '#0b6916',
     confirmButtonText: 'Ok'
          })
        }

        else{
          setLoading(true);
          try {
           
           const data2={ phone};

 var config = {
  method: "post",
  url: "/applicant/verify-phone",

  data: data2,
};  
            // var config = {
            //     method: 'get',
            //     url: `https://api.verxid.site/pallative_recuirment/src/verifyEmail`,
            //     headers: { }
            //   };
              const { data } = await authFetch(config);
              if(data.palliative.status === SUCCESS_STATUS_DIGIT.SUCCESS){
                setLoading(false);
                Swal.fire({
                  title: ' Success',
                  text: 'check your phone',
                  icon: 'success',
                 
                  confirmButtonColor: '#0b6916',
           confirmButtonText: 'Ok'
                })
                
showOt2(true);
              }
              else if(data.palliative.status === SUCCESS_STATUS_DIGIT.FAILURE){
                setLoading(false);
                Swal.fire({
                  title: 'Error',
                  text: `${data.palliative.msg} || please retry`,
                  icon: 'error',
                  confirmButtonColor: '#0b6916',
           confirmButtonText: 'Ok'
                })
                showOt2(false);
              }
          } catch (error) {
            setLoading(false);
              console.log(error)
              Swal.fire({
                title: 'Error',
                text: 'please try again',
                icon: 'error',
               
                confirmButtonColor: '#0b6916',
         confirmButtonText: 'Ok'
              })
          }
        }
      }

//       const verOtp=async()=>{
//         setLoading(true);
//         try {
//             var formdata = new FormData();
//             formdata.append("access-code", accessCode);
            
//             formdata.append("otp", code);
//           var config = {
//             method: "post",
//             url: "https://2023censusadminportal.nationalpopulation.gov.ng/api/verifyOTP",
//             headers: {
//               Authorization: "Basic YmFybmtzZm9ydGUtbmltYzowbmx5YmFybmtzMTIz",
//             },
//             data: formdata,
//             };
//             const { data } = await axios(config);
//             console.log(data)
//             if(data.nimc.status === 1){
             
//               setLoading(false);
//               Swal.fire({
//                 title: 'Success',
//                 text: data.nimc.msg,
//                 icon: 'success',
               
//                 confirmButtonColor: '#0b6916',
//          confirmButtonText: 'Ok'
//               })
            
//                 setVer1(true);
//                 setCode("")
// showOt(false);
//             }
//             else if(data.nimc.status === 0){
//               setLoading(false);
//               Swal.fire({
//                 title: 'Error',
//                 text: 'Invalid OTP Supplied.',
//                 icon: 'error',
               
//                 confirmButtonColor: '#0b6916',
//          confirmButtonText: 'Ok'
//               })
                
//             }
     
//         } catch (error) {
//           setLoading(false);
//             console.log(error)
//             Swal.fire({
//               title: 'Error',
//               text: 'please try again',
//               icon: 'error',
             
//               confirmButtonColor: '#0b6916',
//        confirmButtonText: 'Ok'
//             })
//         }
//     }


    const verOtp=async()=>{

      if(!code){
        Swal.fire({
          title: 'Error',
          text: 'OTP is required',
          icon: 'error',
          confirmButtonColor: '#0b6916',
   confirmButtonText: 'Ok'
        })
        return;
      }
      setLoading(true);
      try {
         
          // formdata.append("access-code", accessCode);
          
        const data3={otp:code};
        var config = {
          method: "post",
        
          url: "/applicant/verify-otp",
         
          data: data3,
          };
          const { data } = await authFetch(config);
          console.log(data)
          if(data.palliative.status === SUCCESS_STATUS_DIGIT.SUCCESS){
              setLoading(false);
              Swal.fire({
                title: 'Success',
                text: 'OTP verified',
                icon: 'success',
                confirmButtonColor: '#0b6916',
         confirmButtonText: 'Ok'
              })
            
                setVer1(true);
                setCode("")
  showOt(false);
        
          }else{
            setLoading(false);
            Swal.fire({
              title: 'Error',
              text: 'OTP is not verified retry',
              icon: 'error',
              confirmButtonColor: '#0b6916',
       confirmButtonText: 'Ok'
            })
          
              setVer1(false);
              setCode("")
showOt(false);

          }

   
      } catch (error) {
        setLoading(false);
          console.log(error)
          Swal.fire({
            title: 'Error',
            text: 'please try again',
            icon: 'error',
           
            confirmButtonColor: '#0b6916',
     confirmButtonText: 'Ok'
          })
      }
  }


  const verOtp2=async()=>{

    if(!code2){
      Swal.fire({
        title: 'Error',
        text: 'OTP is required',
        icon: 'error',
        confirmButtonColor: '#0b6916',
 confirmButtonText: 'Ok'
      })
      return;
    }
    setLoading(true);
    try {
        // var formdata = new FormData();
        // formdata.append("access-code", accessCode);
        
        const data4 ={otp: code2};
      var config = {
        method: "post",
      
        url: "/applicant/verify-otp",
     
        data: data4,
        };
        const { data } = await authFetch(config);
        console.log(data)
        if(data.palliative.status === SUCCESS_STATUS_DIGIT.SUCCESS){
            setLoading(false);
            Swal.fire({
              title: 'Success',
              text: 'OTP verified',
              icon: 'success',
              confirmButtonColor: '#0b6916',
       confirmButtonText: 'Ok'
            })
          
              setVer2(true);
              setCode2("")
showOt2(false);
      
        }else{
          setLoading(false);
          Swal.fire({
            title: 'Error',
            text: 'OTP is not verified retry',
            icon: 'error',
            confirmButtonColor: '#0b6916',
     confirmButtonText: 'Ok'
          })
        
            setVer2(false);
            setCode2("")
showOt2(false);

        }

 
    } catch (error) {
      setLoading(false);
        console.log(error)
        Swal.fire({
          title: 'Error',
          text: 'please try again',
          icon: 'error',
         
          confirmButtonColor: '#0b6916',
   confirmButtonText: 'Ok'
        })
    }
}











       const handleSubmit = async (e) => {
        e.preventDefault();
        
    // if(!ver1){
    //     Swal.fire({
    //         title: ' Verification Unsuccessful!',
    //         text: 'Verify email',
    //         icon: 'warning',
           
    //         confirmButtonColor: '#0b6916',
    //  confirmButtonText: 'Ok'
    //       })
    // }

    if(ver1 === false){
      Swal.fire({
          title: ' Verification Unsuccessful!',
          text: 'Verify email.',
          icon: 'warning',
         
          confirmButtonColor: '#0b6916',
   confirmButtonText: 'Ok'
        })
        return;
  }

  else if(phone.length!== 11 || ver2 === false){
    Swal.fire({
        title: ' Verification Unsuccessful!',
        text: 'Verify phone number.',
        icon: 'warning',
       
        confirmButtonColor: '#0b6916',
 confirmButtonText: 'Ok'
      })
      return;
}

    else{
    
      if(editCon){
        setLoadingSubmit(true);
        try {
   
    
   const data5={
    email, phone,
   edit: EDIT_TYPE.EDIT};
   

    var config = {
      method: "post",
      url: "/applicant/update-contact",
     
      data: data5,
    };
    const { data } = await authFetch(config);
    console.log(data.palliative);
    if (data.palliative.status === SUCCESS_STATUS_DIGIT.FAILED) {
      Swal.fire({
      title: ' Validation Failed!',
      text: data.palliative.message,
      icon: 'error',
     
      confirmButtonColor: '#0b6916',
confirmButtonText: 'Retry'
    })
    }
     else if (data.palliative.status === SUCCESS_STATUS_DIGIT.SUCCESS) {
     Swal.fire({
      title: ' Validation Successful!',
      text: data.palliative.message,
      icon: 'success',
     
      confirmButtonColor: '#0b6916',
confirmButtonText: 'Proceed'
    })
    setLoadingSubmit(false);
    dispatch(handleChange({ name:'editCon', value:false }));
    navigate("/prev"); 
   }
  }
   catch (error) {
    console.log(error);
    setLoadingSubmit(false);
     toast.error("please retry", {
      position: "top-left",
    });
  } } 
      else{
        setLoadingSubmit(true);
              try {
                const data6={
                  email, phone,
                 edit: EDIT_TYPE.NEW};
                 
        
          var config1 = {
            method: "post",
            url: "/applicant/update-contact",
           
            data: data6,
          };
          const { data } = await authFetch(config1);
          console.log(data.palliative);
          if (data.palliative.status === SUCCESS_STATUS_DIGIT.FAILED) {
            Swal.fire({
            title: 'Validation Failed!',
            text: data.palliative.message,
            icon: 'error',
           
            confirmButtonColor: '#0b6916',
     confirmButtonText: 'Retry'
          })
          }
           else if (data.palliative.status === SUCCESS_STATUS_DIGIT.SUCCESS) {
            setLoadingSubmit(false);
           Swal.fire({
            title: ' Validation Successful!',
           text: `Please note that the next step of this application is for the applicant's test assessment`,
            icon: 'success', 
            confirmButtonColor: '#0b6916',
     confirmButtonText: 'Start Test'
          }).then((result) => {
            if(result.value){
              navigate("/test"); 
            }
          })
          // setLoadingSubmit(false);
          // navigate("/test"); 
         }
        }
         catch (error) {
          console.log(error);
          setLoadingSubmit(false);
           toast.error("please retry", {
            position: "top-left",
          });
        }
      };
       
              
      }
  
    }

      const handleInp = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        

        dispatch(handleChange({ name, value }));
      };

      const kpx=(e)=>{
       
        if (e.target.value.length === SUCCESS_STATUS_DIGIT.FAILURE) {
         showOt(false);

      }
    }


    useEffect(() => {
      const getDetails=async ()=>{
        // setPreLoading(true);
        try {
        
          var config = {
            method: "get",
            url: "/auth/retrive-data",
          };
          const { data } = await authFetch(config);
          if(data.palliative.step >= STEP.CONTACT){
            sessionStorage.clear();
         
          }
          // setPreLoading(false);
        } catch (error) {
          console.log(error);
          // setPreLoading(false);
        }
      }       
      getDetails();    
    }, [])
  
    


      
  return ( 
    <>
    <Wrapper2>
    <h4>Contact Information</h4> <br/>
  <p>please perform the validation one after the other. </p>
  <p>NOTE: OTP expires after 2 minutes.</p>
</Wrapper2>
    <Wrapper>

<div className="container">
        {/* <Title title="Contact information"/> */}
        <div className=""  >
        {/* <Progress active={3}/> */}
          <Row className='mt-2'>
            <Col md={6} lg={6}>
            <Form.Label className="text-left">Email address</Form.Label>
            <InputGroup className="mb-3">
           
    <FormControl
    required
     placeholder="Enter email"
     name="email"
      aria-describedby="basic-addon2"
      className="form_input shadow-none"
     type="email"
      value={email}
      onChange={handleInp}
      onKeyUp={kpx}
    />
    <Button  id="button-addon2" className="my-btn" disabled={ver1} onClick={verPhone}>
      {ver1?'verified':'validate email'}
    </Button>
  </InputGroup>


  {
      ot&& <>
      <p className='' style={{
  color:'red',display:'block',cursor:'pointer'
}} onClick={verPhone}>resend otp</p>
      <Form.Label className="text-left">Enter otp</Form.Label>
      <InputGroup className="mb-3">
     
<FormControl
required
type='number'
placeholder="Enter otp"
name="otp"
aria-describedby="basic-addon2"
className="form_input shadow-none"
// maxLength="6"
value={code}
onChange={handleOtpInp}
/>
<Button  id="button-addon2" className="my-btn" onClick={verOtp} >
validate
</Button>

</InputGroup>
      </>
  }
             
            </Col> 

            <Col md={6} lg={6}>
            <Form.Label className="text-left">
                Phone Number
                </Form.Label>
            <InputGroup className="mb-3">
           
    <FormControl
    required
    type="number"
    placeholder="Enter phone number"
    name="phone"
    className="form_input shadow-none"
    value={phone}
    onChange={handleInp}
    onInput={(e) => e.target.value = e.target.value.slice(0, 11)}
    />
    <Button  id="button-addon2" disabled={ver2} className="my-btn" onClick={verPhone2}>
    {ver2?'verified':'validate phone'}
    </Button>
  </InputGroup>
             

  {
      ot2&& <>
      <p className='' style={{
  color:'red',display:'block',cursor:'pointer'
}} onClick={verPhone2}>resend otp</p>
      <Form.Label className="text-left">Enter  phone otp</Form.Label>
      <InputGroup className="mb-3">
     
<FormControl
required
placeholder="Enter otp"
type='number'
name="otp"
aria-describedby="basic-addon2"
className="form_input shadow-none"
// maxLength="15"
value={code2}
onChange={handleOtpInp2}
/>
<Button  id="button-addon2" className="my-btn" onClick={verOtp2} >
validate
</Button>
</InputGroup>
      </>
  }
            </Col>
             
            
 


              </Row>
             

          <Row>
            {/* {!editCon && 
               <Col md={3} lg={3} className="pt-2 ms-auto">
               <button className="redz my-btn btn-block mt-4" onClick={handleSave}>
               {loadingSave &&<Spinner
           as="span"
           animation="grow"
           size="sm"
           role="status"
           aria-hidden="true"
           className='mx-2'
         />
               }
          SAVE AND CLOSE APPLICATION
                
               </button>
             </Col>
            } */}
       
      
            <Col md={3} lg={3} className="pt-2">
              <div style={{
                display:'flex',gap:'1rem'
              }}>
              <button  type='button' onClick={()=>{
                navigate(-1)
              }} disabled={loadingSubmit} className="back-btn btn-block mt-4">{'BACK'}</button>
              <button  type='submit' onClick={handleSubmit} disabled={loadingSubmit} className="my-btn btn-block mt-4">{editCon?'UPDATE':'SAVE AND START TEST'}</button>

              </div>
             
            </Col>
            </Row>
            {loading &&
              <div className="row py-3">
<div className="col-6  col-md-6 mx-auto">
<Spinner animation="border" variant="success" />
</div>
</div>}
              </div>
</div>
    </Wrapper>
      </>
  )
}

const Wrapper = styled.section`
  padding: 1.6rem 0;
 min-height: calc(100vh - 107px);
 text-transform: uppercase;
  background-image:
    linear-gradient(to right,  rgba(245, 246, 252, 0.92), 
    rgba(245, 246, 252, 0.82)),
    url(${pic2});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  p{
    text-transform: lowercase;
}
`;

const Wrapper2=styled.aside`
display:flex;
flex-direction:column;
/* gap:0.2rem; */
justify-content:center;
align-items:center;
width: 100%;
background-color: #0b6916;
color: white;
padding: 0.3rem 0.4rem;
h4,p{
    margin-bottom: 0;
}

`
export default Contact;

