import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Form, Row, Col, Spinner } from "react-bootstrap";
// import { useSelector } from "react-redux";
// import axios from "axios";
import { useNavigate } from "react-router-dom";
import pic2 from "../assets/npc/water2.png";
// import { useDispatch } from "react-redux";
// import { handleChange } from "../redux/userSlice";
import Swal from "sweetalert2/dist/sweetalert2";
import { toast } from "react-toastify";
import Progress from "../components/Progress";
import Title from "../components/Title";
import authFetch from "../axios";
import { STEP, SUCCESS_STATUS_DIGIT } from "../utils";
const Banking = () => {
  // const {editBank } = useSelector((state) => state.user);
  // const dispatch = useDispatch();
  const [bank, setBanks] = useState([]);
  const [code, setCode] = useState("");
  const [numb, setNumb] = useState("");
  const [bankName, setBankName] = useState("");
  const [accName, setAccName] = useState("");
  const [loading, setLoading] = useState(false);
  const [preloading, setPreLoading] = useState(false);
  const [acctyp, setAccType] = useState("");
  // const access = sessionStorage.getItem("access_code");
  const sun = sessionStorage.getItem("lname")?.toLowerCase();
  const fir = sessionStorage.getItem("fname")?.toLowerCase();
  const bearerToken = sessionStorage.getItem("userToken");
  console.log(sun,fir)
  const handleCodeChange = async (e) => {
    setCode(e.target.value);
    setBankName(e.target.options[e.target.selectedIndex].text);
    // console.log(numb, e.target.value);
    const cd = e.target.value;
    setLoading(true);
    try {
     const data={
      accountNumber:numb,
      bankCode:cd.toString()
     }
     
      var config1 = {
        method: "post",
        url: "/resources/validate-account",

        data: data,
      };
      const res = await authFetch(config1);

      if (res.data.status === false && numb) {
        setAccName("");
        setLoading(false);
        Swal.fire({
          title: "Error",
          text: res.data.message,
          icon: "warning",

          confirmButtonColor: "#0b6916",
          confirmButtonText: "Ok",
        });
      } else if (res.data.status === true) {
        setAccName(res.data.data.account_name);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAccType = (e) => {
    const nm = e.target.value;
    setAccType(nm);
  };

  const kp = async (e) => {
    const nb = e.target.value;
    if (
      e.target.value.length === 10 ||
      e.target.value.length > 10 
      // e.target.value.length < 10
    ) {
      setLoading(true);
      try {

        const data1={
          accountNumber:nb,
          bankCode:code.toString()
         }
      var config1 = {
        method: "post",
        url: "/resources/validate-account",
        data: data1,
      };
      const res = await authFetch(config1);
        if (res.data.status === false && code !== "") {
          setAccName("");
          setLoading(false);
          Swal.fire({
            title: "Error",
            text: res.data.message,
            icon: "warning",

            confirmButtonColor: "#0b6916",
            confirmButtonText: "Ok",
          });
        } else if (res.data.status === true) {
          setAccName(res.data.data.account_name);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleChangeC = (e) => {
    setNumb(e.target.value);
  };
  const navigate = useNavigate();
  const handleAppStart = async (e) => {
    e.preventDefault();
    console.log(accName);
    if (!accName) {
      Swal.fire({
        title: " Error",
        text: "please  confirm account details!",
        icon: "error",

        confirmButtonColor: "#0b6916",
        confirmButtonText: "Ok",
      });
    } else if (!acctyp) {
      Swal.fire({
        title: " Error",
        text: "please  select account type!",
        icon: "error",

        confirmButtonColor: "#0b6916",
        confirmButtonText: "Ok",
      });
    } 
    else if (!accName.toLowerCase().includes(sun) && !accName.toLowerCase().includes(fir)) {
      Swal.fire({
        title: " Error",
        text: "please  use your account!",
        icon: "error",

        confirmButtonColor: "#0b6916",
        confirmButtonText: "Ok",
      }); 
    } 
    
    else {
      //   const bank_data ={
      //     numb,
      //   code,
      // bankName,
      //  accName,
      //  acctyp,
      //   }
      // console.log('send')
        
      try {
       const data3= {
          account_number:numb,
        bank_name:bankName,
      account_type:acctyp,
      account_name:accName,
      bank_code:code.toString(),
      }
        var config = {
          method: "post",
          url: "/applicant/update-bank-data",
          data: data3,
        };
        const { data } = await authFetch(config);
        console.log(data.palliative);
        if (data.palliative.status === SUCCESS_STATUS_DIGIT.SUCCESS) {
          toast.success(
            `${data.palliative.message}, further info will be sent to your email`,
            {
              position: "top-center",
            }
          );
          navigate("/prev");
        } else {
          toast.error(data.palliative.message, {
            position: "top-center",
          });
        }
      } catch (error) {
        console.log(error);
        toast.error("please retry", {
          position: "top-left",
        });
      }
    }
  };

  useEffect(() => {
    const getDetails=async ()=>{
      setPreLoading(true);
      try {
     
        var config = {
          method: "get",
          url: "/auth/retrive-data",

       
        };
        const { data } = await authFetch(config);
        console.log(data.palliative[0])
        if(data.palliative.step < STEP.RESIDENCE){
  navigate(-1);
        }
        // sessionStorage.setItem('dataMine',JSON.stringify(data.palliative[0]))
        // setRez(data.palliative[0])
        setPreLoading(false);
      } catch (error) {
        console.log(error);
        setPreLoading(false);
      }
    }
    getDetails();
  }, [navigate,bearerToken])


  useEffect(() => {

    const fetchBanks = async () => {
      try {

        const res = await authFetch.get(
          "/resources/banks", 
        );
        console.log(res.palliative);
        setBanks(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchBanks();
  }, []);
  return (
    <>
{preloading ? <Spinner  
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
          className='mx-auto'/>
          :
          <>
<div className="container">
        <Title title="personal information"/>
        <div className="" >
       
          <Progress active={3}/>
          </div>
          </div>
        

          <Wrapper className="nin-container">

      <div className="container">
        <div className="row">
          <div className="col-10 mx-auto">
            <Form className="">
              
              <h4>Bank details</h4>
              <h5 style={{color:'red',font:'bold'}}>NOTE:Only Commercial Banks are accepted!</h5>
              <h5 style={{color:'red',font:'bold'}}>NOTE: - Applicant's account name must be the same as the name used during application.</h5>
              <Row>
                <Col md={6} lg={6}>
                  <Form.Group className="mb-3" controlId="formBasicEText">
                    <Form.Label className="text-left">
                   <strong> Account Number</strong> 
                    </Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder="Enter account number"
                      name="numb"
                      className="form_input shadow-none"
                      value={numb}
                      onChange={handleChangeC}
                      onKeyUp={kp}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} lg={6}>
                  <Form.Label className="text-left">
                   <strong>
                   Select Bank
                    </strong>
                    </Form.Label>
                  <Form.Select
                    required
                    aria-label="Default select example"
                    name="code"
                    value={code}
                    onChange={handleCodeChange}
                    className="shadow-none form-select"
                  >
                    <option value="">Select Bank</option>
                    {bank.map((item, i) => {
                      return (
                        <option key={i} value={item.code}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
                {accName && !loading ? (
                  <Col md={6} lg={6}>
                    <Form.Label className="text-left">
                    <strong> Select Account type</strong>
                    </Form.Label>
                    <Form.Select
                      required
                      aria-label="Default select example"
                      name="accTyp"
                      value={acctyp}
                      onChange={handleAccType}
                      className="shadow-none form-select"
                    >
                      <option value="">Select Account Type</option>

                      <option value="10">Savings Account</option>

                      <option value="20">Current Account</option>
                    </Form.Select>
                  </Col>
                ) : (
                  ""
                )}
                {accName && !loading ? (
                  <Col md={6} lg={6}>
                    <Form.Group className="mb-3" controlId="formBasicEText">
                      <Form.Label className="text-left">
                      <strong>  Account Name </strong>
                      </Form.Label>
                      <Form.Control
                        readOnly
                        required
                        type="text"
                        name="accname"
                        className="form_input shadow-none"
                        value={accName}
                        // onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
              {loading && (
                <div className="row py-3">
                  <div className="col-6  col-md-6 mx-auto">
                    <Spinner animation="border" variant="success" />
                  </div>
                </div>
              )}

              {/* <button
                className="my-btn  px-5 btn-clear mt-4"
                onClick={() => navigate(-1)}
              >
                Back
              </button> */}

              <button
                type="submit"
                className="my-btn my-2 mx-2 mx-md-4 px-5 "
                disabled={loading}
                onClick={handleAppStart}
              >
                SAVE DETAILS
              </button>
            </Form>
          </div>
        </div>
      </div>
    </Wrapper>
    </>
}
          </>
   
  );
};

const Wrapper = styled.section`
  min-height: calc(100vh - 102.4px);
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  background-image: linear-gradient(
      to right,
      rgba(245, 246, 252, 0.92),
      rgba(245, 246, 252, 0.82)
    ),
    url(${pic2});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
export default Banking;
