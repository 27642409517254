
import React,{useState} from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
const Title = ({title}) => {
  const { accessCode } = useSelector((state) => state.user);
  // eslint-disable-next-line
  const [bttnText, setBttnText] = useState("COPY CODE");
  const copyCode = () => {
    navigator.clipboard
      .writeText(accessCode)
      .then(() => {
        setBttnText("COPIED");
        setTimeout(function () {
          setBttnText("COPY CODE");
        }, 3000);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  return (
    <Wrapper>

        <h4>{title}</h4>
        <p className='mx-0'>Application-ID:{accessCode}</p>
                
<button className="my-btn" onClick={copyCode}>COPY ID</button>
    </Wrapper>
  )
}

const Wrapper=styled.aside`
display:flex;
justify-content:space-between;
align-items:center;
width: 100%;
background-color: #0b6916;
color: white;
padding: 0.3rem 0.4rem;
h4,p{
    margin-bottom: 0;
}

`
export default Title