import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import lpg from "../assets/npc/new-bg.png";
import landingNimc from "../assets/npc/landing-nimc.png";

import Loader from "../components/Loader";
import { toast } from "react-toastify";
import { Modal, Form, Container, Col, Row, Spinner } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import { handleChange } from "../redux/userSlice";
import { useDispatch } from "react-redux";
import authFetch from "../axios";
import { STEP, USER_TYPE,SUCCESS_STATUS_DIGIT } from "../utils";
import Swal from "sweetalert2/dist/sweetalert2";
const Landing = () => { 
  const dispatch = useDispatch();
  const [smShow, setSmShow] = useState(false);


  const [resumeShow, setResumeShow] = useState(false);

  // eslint-disable-next-line
  const [loads, setLod] = useState(false);
  const [appNum, setAppNum] = useState("");
  const [appNum2, setAppNum2] = useState("");


  const [staff, setStaff] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  const start=(num)=>{
    if(num ===USER_TYPE.AGENT){
      dispatch(handleChange({name:'usertype',value:num}))
      sessionStorage.setItem("user-type",num)
      sessionStorage.setItem('checkFirst',true)
      setSmShow(true)
      // setStaff(true) 
    }
          

    else if(num ===USER_TYPE.STAFF){
      dispatch(handleChange({name:'usertype',value:num}))
      sessionStorage.setItem("user-type",num)
      sessionStorage.setItem('checkFirst',true)
      // setStaff(true)
      setSmShow(true)
     
    }
  }

  // const handleCodCheck = async (e) => {
  //   e.preventDefault();
  //   if(!codeNum){
  //     toast.error("please retry", {
  //       position: "top-left",
  //     });
  //     return;
  //   }
  //   else{
  //   setLoading(true);
  //   try {
  //     var formdata = new FormData();
  //     formdata.append("access-code", codeNum);

  //     var config = {
  //       method: "post",
  //       url: "https://npc-recruitment-api.verxid.site/api/retriveData",
  //       headers: {
  //         Authorization: "Basic YmFybmtzZm9ydGUtbmltYzowbmx5YmFybmtzMTIz",
  //       },
  //       data: formdata,
  //     };
  //     const { data } = await axios(config);
  //     console.log(data);
  //     if (data.npc[0].nin ==='') {
  //       setLoading(false);
  //     //   setDataInfo(data.npc[0]);
  //     //   sessionStorage.setItem("access_code", data.npc[0].access_code);
  //     //   sessionStorage.setItem("last_name", data.npc[0].bio_data.lastname);
  //     //   sessionStorage.setItem("first_name", data.npc[0].bio_data.firstname);
  //     dispatch(handleChange({name:'usertype',value:'adhoc'}))
  //     dispatch(handleChange({name:'dqmCode',value:data.npc[0].access_code}))
  //     sessionStorage.setItem('user-type','adhoc')
  //     sessionStorage.setItem('dqmCode',data.npc[0].access_code)
  //     setNwShow(false);
  //     setSmShow(true);
  //     //   if (data.npc[0].bank.account_number === "") {
  //     //     setShowAcc(true);
  //     //   } else {
  //     //     setShowAcc(false);
  //     //   }
  //     //   if (data.npc[0].sign) {
  //     //     setShowSigPrint(true);
  //     //     setShowSig(false);
  //     //   } else {
  //     //     setShowSig(true);
  //     //   }
  //     //   if (data.npc[0].userstatus === 0) {
  //     //     setStat(" Pending");
  //     //   } else if (data.npc[0].userstatus === 1) {
  //     //     setStat("Approved");
  //     //   } else if (data.npc[0].userstatus === 2) {
  //     //     setStat("Declined");
  //     //   }
  //     } else {
  //       setLoading(false);
  //       toast.error("please retry access code", {
  //         position: "top-left",
  //       });
        
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     console.log(error);
  //     toast.error("please retry", {
  //       position: "top-left",
  //     });
  //   }
  // }
  // };
  const handleAppStart = async (e) => {
    e.preventDefault();
const userType = sessionStorage.getItem("user-type")
   if(userType === USER_TYPE.AGENT){
setSmShow(false);
setTimeout(()=>{
  setStaff(true) 
},1500)
   }
   else{
    navigate('/nin');
   }
    // navigate('/nin');
  };
     

  // use this....
  // const newAd=()=>{
  //   dispatch(handleChange({name:'usertype',value:'adhoc'}))
  //   sessionStorage.setItem('user-type','adhoc')
  //   setSmShow(true);
  // }

//   const npcStaff=()=>{
//     dispatch(handleChange({name:'usertype',value:'npc'}))
//     sessionStorage.setItem('user-type','npc')
//     setSmShow(true);
//   }

//   const dqmStaff=()=>{
//     dispatch(handleChange({name:'usertype',value:'dqm'}))
//     sessionStorage.setItem('user-type','dqm');
// setDqmMods(true);

//   }





  const handleResume= async (e)=>{
    sessionStorage.clear();
    e.preventDefault();
    setLoading(true);
    try {
      var jsonData = {
        "code": appNum
      };
      
      var config = {
        method: "post",
        url: `/auth/validate-code`,
        data: jsonData,
        headers: {
          "Content-Type": "application/json"
        }
      };
    
      const { data } = await authFetch(config);
      console.log(data)
      if (data.palliative.status === SUCCESS_STATUS_DIGIT.SUCCESS
        && data.palliative.token
        ) {
          setLoading(true);
          sessionStorage.setItem("userToken", data.palliative.token);
          dispatch(handleChange({name:'accessCode',value:data.palliative.token ||''}));
         
          try {
            var config1 = {
              method: "get",
              url: `/auth/retrive-data`,
            };
      
            const { data } = await authFetch(config1);
            console.log(data)
            // if ( data.palliative.step === STEP.NOT
            //   ){
            //     window.location.href="/face-capture";
            //   }

            //   else if( data.palliative.step === STEP.FACE){
            //       window.location.href="/contact";
            //   }

            //   else if(data.palliative.step === STEP.CONTACT){
            //       window.location.href="/test";
            //   }

             if(data.palliative.step === STEP.TEST  && data.palliative.test_passed ===false){
                  toast.error("you have failed test.", {
                    position: "top-left",
                  });
                  setLoading(false);  
                  return;
              }

            else{
                setLoading(false);  
                     //  test passed....
        sessionStorage.setItem("nin", data.palliative.nin);   
        sessionStorage.setItem("access_code", data.palliative.access_code);
        sessionStorage.setItem("testPassed", true);
        sessionStorage.setItem('user-type',data.palliative.usertype)
        sessionStorage.setItem('fname',data.palliative.bio_data.firstname)
           sessionStorage.setItem('lname',data.palliative.bio_data.lastname)
           sessionStorage.setItem('gender',data.palliative.bio_data.gender)
           sessionStorage.setItem('dob',data.palliative.bio_data.dob)
           sessionStorage.setItem('preState',data.palliative.residence.state_resid_id ||'');
           sessionStorage.setItem('preLga',data.palliative.residence.lga_resid_id ||'');
           sessionStorage.setItem('preName',data.palliative.residence.state_resid||'');
           sessionStorage.setItem('preNameLga',data.palliative.residence.lga_resid||'');
        dispatch(handleChange({name:'preState',value:data.palliative.residence.state_resid_id ||''}));
        dispatch(handleChange({name:'preName',value:data.palliative.residence.state_resid}));
        dispatch(handleChange({name:'preLga',value:data.palliative.residence.lga_resid_id ||''}));
        dispatch(handleChange({name:'preNameLga',value:data.palliative.residence.lga_resid ||''}));
        dispatch(handleChange({name:'usertype',value:data.palliative.usertype}));
        dispatch(handleChange({name:'accessCode',value:data.palliative.access_code}));
        dispatch(handleChange({name:'surname',value:data.palliative.bio_data.lastname ||''}));
        dispatch(handleChange({name:'firstname',value:data.palliative.bio_data.firstname||''}));
        dispatch(handleChange({name:'gender',value:data.palliative.bio_data.gender||''}));
        dispatch(handleChange({name:'date',value:data.palliative.bio_data.dob ||''}));
        dispatch(handleChange({name:'nin',value:data.palliative.nin ||''}));
                         


        if ( data.palliative.step === STEP.NOT
          ){
            // window.location.href="/face-capture";
            navigate("/face-capture");
          }
          else if( data.palliative.step === STEP.FACE){
              // window.location.href="/contact";
              navigate("/contact");
          }
          else if(data.palliative.step === STEP.CONTACT){
              // window.location.href="/test";
              navigate("/test");
              
          }
            
        else if (data.palliative.step === STEP.TEST) {
          // window.location.href="/bio-data";
          navigate("/bio-data");
        }else if (data.palliative.step === STEP.BIODATA) {
          // window.location.href="/residence";
          navigate("/residence");
        } else if (data.palliative.step === STEP.RESIDENCE) {
          // window.location.href="/banking-info";
          navigate("/banking-info");
        }
        else if (data.palliative.step === STEP.COMPLETED) {
          // navigate("/face-capture");
            toast.error("you have completed this application.", {
              position: "top-left",
            });
            return;
       
        }
            }
          
          } catch (error) {
            setLoading(false);
            console.log(error);
            toast.error("please retry", {
              position: "top-left",
            });
          }
  }
  else {
    setLoading(false);
    toast.error("please retry access code", {
      position: "top-left",
    });
  }
}
catch (error) {
  setLoading(false);
  console.log(error);
  toast.error("please retry", {
    position: "top-left",
  });
}
  }



  const handleResume2= async (e)=>{
    e.preventDefault();
    setLoading(true);
    try {
var jsonData = {
  "code": appNum2
};

var config = {
  method: "post",
  url: `/auth/validate-code`,
  data: jsonData,
  headers: {
    "Content-Type": "application/json"
  }
};

const { data } = await authFetch(config);
console.log(data);

      if (data.palliative.status === SUCCESS_STATUS_DIGIT.SUCCESS
        && data.palliative.token
        ) {
          setLoading(true);
          sessionStorage.setItem("userToken", data.palliative.token);
          dispatch(handleChange({name:'accessCode',value:data.palliative.token ||''}));
          try {
            // const bearerToken = sessionStorage.getItem("userToken");
            var config1 = {
              method: "get",
              // url:'https://api.verxid.site/pallative_recuirment/src/validateCode',
              url: `/auth/retrive-data`,
              // headers: {
              //   Authorization: `Bearer ${bearerToken}`,
              // },
            };
            const { data } = await authFetch(config1);
            console.log(data.palliative.step)

            if ( data.palliative.step ===STEP.NOT && data.palliative.test_passed===false){
                setLoading(false);  
                     //  test passed....
        // sessionStorage.setItem("nin", data.palliative[0].nin);   
        // sessionStorage.setItem("access_code", data.palliative[0].access_code);
        // sessionStorage.setItem("testPassed", true);
        // sessionStorage.setItem('user-type',data.palliative[0].usertype)
       
        sessionStorage.setItem('preState',data.palliative.residence.state_resid_id);
           sessionStorage.setItem('preLga',data.palliative.residence.lga_resid_id);
           sessionStorage.setItem('preName',data.palliative.residence.state_resid);
           sessionStorage.setItem("access_code", data.palliative.access_code);
           sessionStorage.setItem('preNameLga',data.palliative.residence.lga_resid||'');
        dispatch(handleChange({name:'preState',value:data.palliative.residence.state_resid_id}));
        dispatch(handleChange({name:'preLga',value:data.palliative.residence.lga_resid_id}));
        dispatch(handleChange({name:'preName',value:data.palliative.residence.state_resid}));
        dispatch(handleChange({name:'accessCode',value:data.palliative.access_code}));
        dispatch(handleChange({name:'preNameLga',value:data.palliative.residence.lga_resid ||''}));
        setStaff(false); 
        // setSmShow(true);   
        Swal.fire({    
          title: `ACCESS CODE SUCCESSFULLY VALIDATED`,
          // text: 'success',
          icon: 'success',
          // text: `please save your application ID`,
          text: `Continue with your application`,
          confirmButtonColor: '#0b6916',
    confirmButtonText: 'Continue'
        }).then((result) => {
          if(result.value){
navigate('/nin');
          }
        })
        // if (data.palliative[0].step === '1') {
        //   window.location.href="/residence";
        // }else if (data.palliative[0].step === '0') {
        //   window.location.href="/bio-data";
        // } else if (data.palliative[0].step === '2') {
        //   window.location.href="/banking-info";
        // }
     
      
      
        // else if (data.palliative[0].step === '3') {
        //   // navigate("/face-capture");
        //     toast.error("you have completed this application.", {
        //       position: "top-left",
        //     });
        //     return;   
       
        // }
            }
            else {
              setLoading(false);
              toast.error("Invalid access code", {
                position: "top-left",
              });
              return;
            }
          } catch (error) {
            setLoading(false);
            console.log(error);
            toast.error("please retry", {
              position: "top-left",
            });
          }
  }
  else {
    setLoading(false);
    toast.error("Invalid Code access code", {
      position: "top-left",
    });
  }
}
catch (error) {
  setLoading(false);
  console.log(error);
  toast.error("Invalid Code", {
    position: "top-left",
  });
}
  }



  return (
    <Wrapper>
      {loads && <Loader />}
      {/* <span className="policy text-white"><a href={'https://barnksforte-statics.s3.eu-central-1.amazonaws.com/npc/E-RECRUITMENT_PRIVACY+POLICY.pdf'} rel="noreferrer" target='_blank'>Privacy Policy</a></span> */}
      <div className="sec-row">
        <div className="left-side">
           {/* <img src={header} alt="peep" className="img mx-0" style={{width:'300px',height:'100px'}} /> */}
          <h4 className=''>Palliative Exercise Agent E - Recruitment
          
          </h4>
          <p className="" style={{
            fontSize:'15px'
          }}>Note that application is free and approvals are based on merit and any 
citizen posing as an agent collecting money from the applicants to fast 
track or induce approvals will be found and duly prosecuted to the 
full extent of the law.

</p>
          <div className="dwn mx-0">
            <div className="cta-btns mx-0">
                        
                  {/* locked */}

                        
                          
                           


            <Dropdown className=" px-0 mx-0">
      <Dropdown.Toggle variant="success" id="dropdown-basic">
      Start Application
      </Dropdown.Toggle>
      <Dropdown.Menu>
        
        <Dropdown.Item  onClick={() => start('1')}>Start as Agent </Dropdown.Item>
        <Dropdown.Item  onClick={() => start('2')}>Start as NIMC staff </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>




              {/* <button 
              // disabled={rl}
               className="my-btn small" onClick={() => setSmShow(true)}>
              Start Application
            </button> */}
              
              {/* <button
                className="my-btn yellow-clr small py-2"
                // disabled={rl}
                onClick={() => setSmShow(true)}
              >
               Start Application
              </button> */}
              <button
                className="my-btn  small py-2"
                // disabled={rl}
                style={
                  {
                    backgroundColor:'transparent',
                    border:'1px solid white'
                  }
                }
                onClick={() =>  setResumeShow(true)}
              >
               Resume Application
              </button>
            </div>
          </div>
        </div>

      
      </div>

      <Modal
        size="lg"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header className="modal-header" closeButton>
          <Container>
            <Row className="text-center">
              <Col sm={12} md={12}>
                <Modal.Title
                  id="example-modal-sizes-title-sm"
                  className="mx-auto"
                >
                  &nbsp;&nbsp;&nbsp;&nbsp; APPLICATION INSTRUCTIONS
                </Modal.Title>
              </Col>
              <Col sm={12} md={12}>
                <Modal.Body className="mx-auto">
                  {" "}
                  Please Note!{" "}
                </Modal.Body>
              </Col>
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">
            {" "}
            <span style={{ color: "red" }}>
            Carefully read and acknowledge the below instructions to start application
            </span>
          </p>
          <Form className="" onSubmit={handleAppStart}>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                checked
                type="checkbox"
                label="Applicant must have a valid National Identification Number (NIN)."
                className="shadow_none form_check"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                checked
                type="checkbox"
                label="Applicant must write and pass an assessment test. "
                className="shadow_none form_check"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                 checked
                type="checkbox"
                label="Be informed that you can not take the assessment test more than once."
                className="shadow_none form_check"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                 checked
                type="checkbox"
                label="Applicant must use a device with a camera"
                className="shadow_none form_check"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                 checked
                type="checkbox"
                label="Applicant's bank details must be the same as the details that will be collected from his/her NIN"
                className="shadow_none form_check"
              />
            </Form.Group>


            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                 checked
                type="checkbox"
                label="Applicant must have a valid educational certificate"
                className="shadow_none form_check"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                 checked
                type="checkbox"
                label="Applicant must have a valid email account"
                className="shadow_none form_check"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                checked
                type="checkbox"
                label="Applicant must score 50% and above on the assessment test."
                className="shadow_none form_check"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                 checked
                type="checkbox"
                label="Valid Bank Account Number"
                className="shadow_none form_check"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                checked
                type="checkbox"
                label="Applicant's National Identification Number (NIN) can only be used once to assess the assessment test"
                className="shadow_none form_check"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                checked
                type="checkbox"
                label="Applicant must have a valid phone number"
                className="shadow_none form_check"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
               required
                type="checkbox"
                label="I have carefully read and understood the above instructions"
                className="shadow_none form_check"
              />
            </Form.Group>

         
            <div className="d-flex justify-content-center">
              <button
                type="submit"
                className="my-btn"
                style={{ width: "200px" }}
              >
               Continue
              </button>
            </div>
          </Form>
          <div className="d-flex justify-content-center">
            <button
              className="my-btn btn-clear mt-2"
              style={{ width: "200px" }}
              onClick={() => setSmShow(false)}
            >
              CLOSE
            </button>
          </div>
        </Modal.Body>
      </Modal>






      {/* <Modal
        size="md"
        show={nwShow}
        onHide={() => setNwShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header className="modal-header" closeButton>
          <Container>
            <Row className="text-center">
              <Col sm={12} md={12}>
                <Modal.Title
                  id="example-modal-sizes-title-sm"
                  className="mx-auto"
                > 
                  &nbsp;&nbsp;&nbsp;&nbsp;APPLY WITH CODE
                </Modal.Title>
              </Col>
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Body>
          <Form className="" onSubmit={handleCodCheck}>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Control
                required
                type="text"
                placeholder="Enter Your  Code"
                className="form_input shadow-none"
                name="app_code"
                onChange={(e) => setCodeNum(e.target.value)}
              />
            </Form.Group>

            <div className="d-flex justify-content-center">
            
                <button
                  type="submit"
                  className="my-btn"
                  onClick={handleCodCheck}
                >
                  PROCEED
                </button>
            </div>
          </Form>
          <button
            className="my-btn btn-clear mx-auto"
            onClick={() => {
              setNwShow(false);
              setCodeNum("");
              sessionStorage.removeItem("access_code");
              sessionStorage.clear();
            }}
          >
            CLOSE
          </button>

          {/* {showSig && (
            <div>
              <Link to="/sign-agree">
                <button className="btn my-btn">Sign Agreement</button>
              </Link>
            </div>
          )}

          {showSigPrint && (
            <div>
              <Link to="/print">
                <button className="btn my-btn">Print Agreement</button>
              </Link>
            </div>
          )} 
          {loading && (
            <div className="row py-3">
              <div className="col-6  col-md-6 mx-auto">
                <Spinner animation="border" variant="success" />
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal> */}


      <Modal
        size="md"
        show={resumeShow}
        onHide={() => setResumeShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header className="modal-header" closeButton>
          <Container>
            <Row className="text-center">
              <Col sm={12} md={12}>
                <Modal.Title
                  id="example-modal-sizes-title-sm"
                  className="mx-auto"
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;RESUME APPLICATION
                </Modal.Title>
              </Col>
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Body>
          <Form className="" onSubmit={handleResume}>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Control
                required
                type="text"
                placeholder="Enter Your Application Code/NIN"
                className="form_input shadow-none"
                name="app_code"
                onChange={(e) => setAppNum(e.target.value)}
              />
            </Form.Group>
                <button
                  type="submit"
                  className="my-btn"
            
                >
                  RESUME APPLICATION
                </button>
             
          </Form>
         
          {loading && (
            <div className="row py-3">
              <div className="col-6  col-md-6 mx-auto">
                <Spinner animation="border" variant="success" />
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>



      <Modal
        size="md"
        show={staff}
        onHide={() => 
         { 
          setStaff(false);
          // setSmShow(true);
         }
        }
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header className="modal-header" closeButton>
          <Container>
            <Row className="text-center">
              <Col sm={12} md={12}>
                <Modal.Title
                  id="example-modal-sizes-title-sm"
                  className="mx-auto"
                >
                  &nbsp;&nbsp;&nbsp;&nbsp; Registration Agent Access Code
                </Modal.Title>
              </Col>
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Body>
        <p>All Registration agents must validate access code to commence with application.</p>
          <Form className="" onSubmit={handleResume2}>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Control
                required
                type="text"
                placeholder="Enter your access code"
                className="form_input shadow-none"
                name="app_code"
                onChange={(e) => setAppNum2(e.target.value)}
              />
            </Form.Group>
                <button
                  type="submit"
                  className="my-btn"
            
                >
                 Validate Access Code
                </button>
             
          </Form>
         
          {loading && (
            <div className="row py-3">
              <div className="col-6  col-md-6 mx-auto">
                <Spinner animation="border" variant="success" />
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>


           <img src={landingNimc} alt="img" className="img mx-0 imgh" style={{width:'200px',height:'130px',position:'fixed',left:'50px',bottom:'80px'}} />
    </Wrapper>
  );
};

const Wrapper = styled.main`
  min-height: 100vh;
  width: 100%;
  background-image: url(${lpg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position:relative;
display:flex;
justify-content:end;
align-items:center;
padding:0px 15px;
  .policy{
    position:absolute;
    bottom:5px;
    right:5px;
    font-size: 1rem;
    a{
    color:white;
    font-weight:bolder;
    }
  }
  .sec-row {
    display: flex;
    /* grid-template-columns: 1fr; */
    /* gap: 0.6rem; */
    /* background-color:red; */
    background-image: 
    linear-gradient(to right, rgba(0,0,0,0.4), rgba(0,0,0,0.6));
    padding: 4rem 5rem;
    .left-side {
      color: var(--white);
      padding-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
      .dwn {
        /* margin-top: 8rem; */

      }
      h1,
      h3 {
        margin-bottom: 1rem;
      }
      p {
        font-size: 1.7rem;
      }

      .cta-btns {
        display: flex;
        /* justify-content: center; */
        align-items: center;
        gap: 0.7rem;
      }
    }
  
  }

  @media (max-width: 1000px) {
    .sec-row .left-side {
      padding-top: 3.4rem;
    }
    .sec-row .left-side .dwn {
      margin-top: 0rem;
    }
  }

  @media (max-width: 850px) {
   .imgh{
    display: none;
   }
    .sec-row {
      grid-template-columns: 1fr;
      text-align: center;
      padding-top: 1rem;
    }

    .sec-row .left-side .cta-btns {
      justify-content: center;
    }
  
  }
  @media (max-width: 500px) {
    .sec-row {
     
      text-align: center;
      padding-top: 1rem;
    }
    .sec-row .left-side .cta-btns {
      flex-direction: column;
      gap: 0.6rem;
    }
  }
`;

export default Landing;
