export const EDIT_TYPE = {
    EDIT: "1",
    NEW: "0"
  }

  export const SUCCESS_STATUS={
    SUCCESS:'1',
    FAILURE:'0'
  }

  export const SUCCESS_STATUS_DIGIT={
    SUCCESS:1,
    FAILURE:0
  }

  export const USER_TYPE={
    AGENT:'1',
    STAFF:'2'
  }

  export const STEP={
    NOT:0,
    FACE:1,
    CONTACT:2,
    TEST:3,
    RESIDENCE:5,
BIODATA:4,
BANKING:6,
COMPLETED:6
  }